import React from "react";
import { Input } from "../types";

interface EditorInputPropTypes {
  type: Input;
  inputType: string;
  editorAttributes: Record<string, any>;
  onBlurEvent?: (event: React.FocusEvent<HTMLInputElement>) => Promise<void>;
  onChangeEvent?: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  inputRef?: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export function EditorInput(props: EditorInputPropTypes) {
  const {
    inputType,
    type,
    editorAttributes,
    onBlurEvent,
    onChangeEvent,
    inputRef,
    className,
    style,
    children,
    ...attributes
  } = props;
  return React.createElement(
    inputType,
    {
      ...attributes,
      ...editorAttributes,
      className: type !== Input.Hidden ? className : null,
      onChange: type !== Input.Hidden ? onChangeEvent : null,
      onBlur: type !== Input.Hidden ? onBlurEvent : null,
      ref: inputRef,
      style: style,
    },
    children
  );
}
