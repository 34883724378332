import { AdminUtil } from "src/util";

export interface FuncaoProps {
  id: number;
  novidade?: any;
  label: string;
  icon?: string;
  link?: string;
  badge?: string;
  badgecolor?: string;
  description?: string;
  menu?: boolean;
}
export interface ModuloProps {
  id: number;
  label: string;
  icon?: string;
  novidade?: any;
  funcoes: FuncaoProps[];
}
export interface GrupoProps {
  label: string;
  icon?: string;
  modulos?: ModuloProps[];
  funcoes?: FuncaoProps[];
}
const menuItems: Array<GrupoProps> = require("src/menu.json");

// Função para gerar um menu filtrado com base nas permissões do usuário
export const gerarMenuFiltrado = (
  funcoesPermitidas: number[],
  usuarioAdministrador: boolean
): Array<GrupoProps> => {
  if (usuarioAdministrador) {
    return menuItems; // Se for administrador, retorna o menu completo
  }

  return (menuItems || [])
    .map((grupo) => {
      const modulosFiltrados = (grupo.modulos || [])
        .map((modulo) => {
          const funcoesFiltradas = (modulo.funcoes || []).filter((funcao) =>
            funcoesPermitidas.includes(funcao.id)
          );

          return funcoesFiltradas.length > 0
            ? { ...modulo, funcoes: funcoesFiltradas }
            : null;
        })
        .filter(Boolean); // Remove os módulos vazios

      return modulosFiltrados.length > 0
        ? { ...grupo, modulos: modulosFiltrados }
        : null;
    })
    .filter(Boolean); // Remove os grupos vazios
};

// Função para gerar o menu de favoritos filtrado com base nas permissões e favoritos do usuário
export const gerarMenuFavoritos = (
  funcoesPermitidas: number[],
  usuarioAdministrador: boolean
): ModuloProps[] => {
  const listaFavorito = AdminUtil.obterFavoritos();
  const modulosComFuncoes: ModuloProps[] = [];

  // Filtrar itens favoritos
  for (const grupo of menuItems) {
    if (grupo.modulos) {
      for (const modulo of grupo.modulos) {
        if (modulo.funcoes && modulo.funcoes.length > 0) {
          modulosComFuncoes.push(modulo);
        }
      }
    }
  }

  const listaMenuFavoritos = modulosComFuncoes
    .map((modulo) => ({
      id: modulo.id,
      icon: modulo.icon,
      label: modulo.label,
      funcoes: modulo.funcoes.filter(
        (funcao) =>
          listaFavorito.includes(funcao.id) &&
          (usuarioAdministrador || funcoesPermitidas.includes(funcao.id))
      ),
    }))
    .filter((item) => item.funcoes.length > 0);

  return listaMenuFavoritos;
};
// Exporta o menu completo
export { menuItems };
