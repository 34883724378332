import React, { useEffect, useState } from "react";

// image
import logolight from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png";
import { Column, ColumnSize, Editor, Input, Row } from "src/SmartR";

import avatar from "../../assets/images/users/avatar.png";
import gomelius from "src/api/gomelius";
import { ToastNotification } from "src/util/ToastNotification";
import { withRouter} from "src/hocs";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { inicializar } from "src/slices/Auth/reducer";
import { Loading } from "src/components";
const Renovar = (props) => {
  const dispatch = useDispatch();
  const { onConfirmar } = props;
  const { location, navigate } = props.router;
  const [carregando, setCarregando] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [versao, setVersao] = useState(0);
  const [senha, setSenha] = useState("");
  const urlAvatar =
    usuario && usuario.temFoto
      ? `https://aetmaster.blob.core.windows.net/usuario-avatar/${usuario.id}.png?v=${versao}`
      : avatar;

  const changeSenhaHandler = (event) => {
    const value = event.target.value;
    setSenha(value);
  };

  const desbloquearHandler = () => {
    if (senha) {
      setCarregando(true);
      gomelius
        .renovarToken(senha)
        .then(function (dados: any) {
          setCarregando(false);
          dispatch(
            inicializar({
              autenticado: true,
              usuario: dados.usuario,
              versao: dados.versao,
              renovarSessao: false,
            })
          );
          if (onConfirmar) {
            onConfirmar(dados);
          } else {
            // Caso contrário, verifica se há um `from` no location.state
            const from = location.state?.from || "/"; // Define "/" como valor padrão se `from` não existir
            navigate(from); // Navega para o "from" ou para a home se `from` não estiver definido
          }
        })
        .catch((dados: any) => {
          setCarregando(false);
        });
    } else {
      ToastNotification.error("Informe sua senha", "Autenticar Usuário");
    }
  };
  useEffect(() => {
    document.body.classList.add("renovar-sessao");
    gomelius.obterUsuarioAutenticado(false).then((data: any) => {
      setUsuario(data.usuario);
      setVersao(data.versao);
    });
    return () => {
      document.body.classList.remove("renovar-sessao");
    };
  }, []);
  if (carregando) return <Loading />;
  return (
    <React.Fragment>
      <div className="base-renovar">
        <div className="bg-overlay bg-white"></div>
        <Row className="justify-content-center my-auto">
          <Column
            md={ColumnSize.Col8}
            lg={ColumnSize.Col6}
            xl={ColumnSize.Col4}
          >
            <div className="text-center py-5">
              <div className="mb-4 mb-md-5">
                <a href="#!" className="d-block auth-logo">
                  <img
                    src={logoDark}
                    alt=""
                    height="22"
                    className="auth-logo-dark"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="22"
                    className="auth-logo-light"
                  />
                </a>
              </div>
              <div className="mb-2">
                <div className="avatar-xl mx-auto">
                  <div className="avatar-title bg-light text-primary display-4 rounded-circle">
                    <img
                      src={urlAvatar}
                      alt=""
                      className="rounded-circle img-thumbnail"
                    />
                  </div>
                </div>
              </div>
              <div className="text-muted">
                <h4 className="">
                  Olá <b>{usuario && usuario.nome}</b> entre com sua senha
                  novamente!
                </h4>
                <Editor
                  size={ColumnSize.Col12}
                  type={Input.Password}
                  title="Senha"
                  hasTitle={false}
                  id="senha"
                  requiredMessage="Informe a senha que você definiu quando realizou o cadastro"
                  required={true}
                  value={senha}
                  onChange={changeSenhaHandler}
                  placeholder="Informe sua senha"
                />
                <div className="mt-3">
                  <button
                    className="btn btn-info w-100"
                    type="button"
                    onClick={desbloquearHandler}
                  >
                    Desbloquear
                  </button>
                </div>
                <div className="mt-4">
                  Não é você?{" "}
                  <Link
                    to="/Login"
                    className="fw-medium text-decoration-underline"
                  >
                    Acesse aqui
                  </Link>
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Renovar);
