import { createSlice } from "@reduxjs/toolkit";

interface AutenticacaoStateType {
  autenticado: boolean;
  renovarSessao: boolean;
  usuario?: any;
  versao?: number;
  erroAutenticacao?: string | object | null | undefined | unknown;
  selecionarEmpresa?: any;
  selecionarCliente?: any;
}
export const initialState: AutenticacaoStateType = {
  autenticado: false,
  renovarSessao: false,
  usuario: null,
  versao: 1,
  erroAutenticacao: "",
  selecionarEmpresa: "",
  selecionarCliente: "",
};

const autenticacaoSlice = createSlice({
  name: "autenticacao",
  initialState,
  reducers: {
    inicializar(state, action) {
      const { autenticado, usuario, versao, renovarSessao } = action.payload;
      if (autenticado) {
        // Caso autenticado seja true, mantém como está
        state.autenticado = autenticado;
        state.usuario = usuario;
        state.versao = versao;
        state.renovarSessao = renovarSessao;
      } else if (!autenticado && renovarSessao) {
        // Caso autenticado seja false e renovarSessao seja true, atualiza apenas renovarSessao
        state.renovarSessao = renovarSessao;
      } else if (!autenticado && !renovarSessao) {
        // Caso autenticado e renovarSessao sejam false, define usuario como nulo e versao como 0
        state.usuario = null;
        state.versao = 0;
        state.autenticado = false;
        state.renovarSessao = false;
      }
    },
    atualizar(state, action) {
      const { usuario, versao } = action.payload;
      state.usuario = usuario;
      state.versao = versao;
    },
    erroAutenticarUsuario(state, action) {
      state.erroAutenticacao = action.payload;
    },
    usuarioAutenticadoSucesso(state, action) {
      const { usuario, versao } = action.payload;
      state.usuario = usuario;
      state.versao = versao;
      state.erroAutenticacao = ""; // Limpa o erro de autenticação
      state.selecionarEmpresa = ""; // Reseta a seleção de empresa
      state.selecionarCliente = ""; // Reseta a seleção de cliente
    },
    selecionarEmpresa(state, action) {
      state.selecionarEmpresa = action.payload;
      state.erroAutenticacao = ""; // Limpa o erro de autenticação
      state.selecionarCliente = ""; // Reseta a seleção de cliente
    },
    selecionarCliente(state, action) {
      state.selecionarCliente = action.payload;
      state.erroAutenticacao = ""; // Limpa o erro de autenticação
      state.selecionarEmpresa = ""; // Reseta a seleção de empresa
    },
  },
});

export const {
  inicializar,
  atualizar,
  erroAutenticarUsuario,
  usuarioAutenticadoSucesso,
  selecionarEmpresa,
  selecionarCliente,
} = autenticacaoSlice.actions;

export default autenticacaoSlice.reducer;
