import React, { createContext, useContext, useEffect, useCallback } from "react";

export type BroadcastMessage = {
  id?: string;
  type: string;
  subType?: string | number;
  data?: any;
  identifier?: string;
};

type BroadcastContextType = {
  sendMessage: (message: BroadcastMessage) => void;
  onMessage: (callback: (message: BroadcastMessage) => void) => () => void;
};

const BroadcastContext = createContext<BroadcastContextType | null>(null);

export const BroadcastProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  // Instancia o canal diretamente no provider
  const channel = new BroadcastChannel("notificacao");

  const sendMessage = useCallback((message: BroadcastMessage) => {
    try {
      channel.postMessage(message);
    } catch (error) {
      console.error("Erro ao enviar mensagem pelo BroadcastChannel:", error);
    }
  }, []);

  const onMessage = useCallback((callback: (message: BroadcastMessage) => void) => {
    const handleMessage = (event: MessageEvent) => {
      callback(event.data as BroadcastMessage);
    };
    channel.addEventListener("message", handleMessage);

    // Retorna função de limpeza correta
    return () => {
      channel.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      channel.close();
    };
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      channel.close();
    };
  }, []);

  return (
    <BroadcastContext.Provider value={{ sendMessage, onMessage }}>
      {children}
    </BroadcastContext.Provider>
  );
};

// Hook para acessar o contexto
export const useBroadcast = () => {
  const context = useContext(BroadcastContext);
  if (!context) {
    throw new Error("useBroadcast deve ser usado dentro de BroadcastProvider");
  }
  return context;
};

export default BroadcastContext;
