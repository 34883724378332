import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useState,
} from "react";

import { Column, Row } from "src/SmartR";
import gomelius from "src/api/gomelius";

import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { ToastNotification } from "src/util";

import { GramaticaHelper } from "src/helpers";
import { Loading } from "../organisms/Loading";
import { Table } from "../organisms/Table";
import { useLayoutContext } from "src/contexts/layoutContext";
import { TableColumnProps } from "../organisms/Table/types";
import { Tooltip, TooltipPosition } from "src/components";
export interface TableContainerRef {
  getRecords: () => any[];
  getRecord: (id: string) => any;
  updateRecord: (id: string, newData: Partial<any>) => void;
  removeRecord: (id: string) => void;
  addRecord: (newRecord: any) => void;
}
interface TableContainerProps {
  columns?: TableColumnProps[];
  columnsDetail?: TableColumnProps[];
  data?: any;
  TableContainerRef?: React.Ref<TableContainerRef>;
  dataDetailProperty?: string;
  showHeader?: boolean;
  showDetailHeader?: boolean;
  enableHoverEffect?: boolean;
  enableHoverEffectDetail?: boolean;
  singleRecordTable?: boolean;
  uniqueHeaderSingleRecordTable?: boolean;
  customPageSize?: any;
  className?: any;
  classNameDetail?: any;
  customPageSizeOptions?: any;
  filterForm?: Record<string, any>;
  urlFetch?: string;
  urlDelete?: string;
  urlInactive?: string;
  urlReactive?: string;
  onLoadingStart?: any;
  onLoadingEnd?: any;
  timeZone?: string;
  culture?: string;
  onDoubleClick?: (rowData: any, rowIndex: number) => void;
  onDoubleClickDetail?: (
    rowData: any,
    rowDataParent: any,
    rowIndex: number,
    rowParentIndex: number
  ) => void;
}
const TableContainer = forwardRef<TableContainerRef, TableContainerProps>(
  (
    {
      columns,
      columnsDetail,
      dataDetailProperty,
      showDetailHeader = true,
      showHeader = true,
      enableHoverEffect = true,
      enableHoverEffectDetail = true,
      singleRecordTable = false,
      uniqueHeaderSingleRecordTable = false,
      data = [],
      className,
      classNameDetail,
      filterForm,
      urlFetch,
      urlDelete,
      urlInactive,
      urlReactive,
      culture = "pt-BR",
      timeZone = "Central Brazilian Standard Time",
      onLoadingStart,
      onLoadingEnd,
      onDoubleClick,
      onDoubleClickDetail,
    }: TableContainerProps,
    ref
  ) => {
    const { layoutReady } = useLayoutContext();

    const [registros, setRegistros] = useState(data);
    const [totalPagina, setTotalPagina] = useState(0);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [totalRegistrosPagina, setTotalRegistrosPagina] = useState(10);
    const [registrosPagina, setRegistrosPagina] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const acaoListaSelector = createSelector(
      (state: any) => state.acaoLista,
      (acao) => ({
        dadosRemover: acao.removerServidor,
        dadosInativar: acao.inativarRegistro,
        dadosReativar: acao.reativarRegistro,
      })
    );

    const { dadosRemover, dadosInativar, dadosReativar } =
      useSelector(acaoListaSelector);

    const carregarPagina = async (pagina) => {
      setPaginaAtual(pagina);
      setTotalRegistrosPagina(registrosPagina);
      obterRegistros(pagina);
    };

    const obterRegistros = async (pagina) => {
      const formFiltro = {
        ...filterForm,
        pagina: pagina,
        registrosPagina: registrosPagina,
      };
      setIsLoading(true);
      if (urlFetch) {
        if (onLoadingStart) {
          onLoadingStart();
        }
        if (filterForm) {
          gomelius
            .obterListaFiltro(formFiltro, urlFetch)
            .then(function (info: any) {
              setRegistros(info.lista);
              setTotalRegistrosPagina(info.lista.length);

              if (pagina === 1) {
                setTotalPagina(info.totalPagina);
                setTotalRegistros(info.totalRegistro);
              }
              if (onLoadingEnd) {
                onLoadingEnd(info.lista);
              }
              setIsLoading(false);
            })
            .catch(function (erro) {
              console.error(erro);
            });
        } else {
          gomelius
            .obterLista(urlFetch)
            .then(function (lista) {
              setRegistros(lista);

              if (onLoadingEnd) {
                onLoadingEnd(lista);
              }
              setIsLoading(false);
            })
            .catch(function (erro) {
              console.error(erro);
            });
        }
      } else {
        setIsLoading(false);
      }
    };

    // Implementação dos métodos da interface no `useImperativeHandle`
    useImperativeHandle(ref, () => ({
      getRecords: () => {
        return registros;
      },
      getRecord: (id) =>
        registros.find(
          (registro) => registro.id.toLowerCase() === id.toLowerCase()
        ),
      updateRecord: (id, newData) => {
        setRegistros((prevRegistros) =>
          prevRegistros.map((registro) =>
            registro.id === id ? { ...registro, ...newData } : registro
          )
        );
      },
      removeRecord: (id) => {
        setRegistros((prevRegistros) =>
          prevRegistros.filter((registro) => registro.id !== id)
        );
      },
      addRecord: (newRecord) => {
        setRegistros((prevRegistros) => [newRecord, ...prevRegistros]);
      },
    }));

    useEffect(() => {
      if (dadosRemover && urlDelete) {
        gomelius
          .delete(dadosRemover.id, urlDelete)
          .then(function (resposta) {
            setRegistros(
              registros.filter((Filter) => Filter.id !== dadosRemover.id)
            );

            ToastNotification.success(
              `${GramaticaHelper.obterArtigoGramatical(
                dadosRemover.titulo
              ).toUpperCase()} ${dadosRemover.titulo.toLowerCase()} foi excluíd${GramaticaHelper.obterArtigoGramatical(
                dadosRemover.titulo
              ).toLowerCase()} com sucesso!`
            );
            setTotalRegistros(totalRegistros - 1);
            setTotalRegistrosPagina(totalRegistrosPagina - 1);
          })
          .catch(function (resposta) {});
      } else if (dadosInativar && urlInactive) {
        gomelius
          .put(dadosInativar.id, null, urlInactive)
          .then(function (resposta) {
            const registroIndex = registros.findIndex(
              (registro) => registro.id === dadosInativar.id
            );

            if (registroIndex !== -1) {
              const novosRegistros = [...registros];
              novosRegistros[registroIndex].situacao = 2;
              setRegistros(novosRegistros);
            }

            ToastNotification.success(
              `${GramaticaHelper.obterArtigoGramatical(
                dadosInativar.titulo
              ).toUpperCase()} ${dadosInativar.titulo.toLowerCase()} foi inativad${GramaticaHelper.obterArtigoGramatical(
                dadosInativar.titulo
              ).toLowerCase()} com sucesso!`
            );
          })
          .catch(function (resposta) {});
      } else if (dadosReativar && urlReactive) {
        gomelius
          .put(dadosReativar.id, null, urlReactive)
          .then(function (resposta) {
            const registroIndex = registros.findIndex(
              (registro) => registro.id === dadosReativar.id
            );

            if (registroIndex !== -1) {
              const novosRegistros = [...registros];
              novosRegistros[registroIndex].situacao = 1;
              setRegistros(novosRegistros);
            }

            ToastNotification.success(
              `${GramaticaHelper.obterArtigoGramatical(
                dadosReativar.titulo
              ).toUpperCase()} ${dadosReativar.titulo.toLowerCase()} foi reativad${GramaticaHelper.obterArtigoGramatical(
                dadosReativar.titulo
              ).toLowerCase()} com sucesso!`
            );
          })
          .catch(function (resposta) {});
      }
    }, [dadosRemover, dadosInativar, dadosReativar]);

    useLayoutEffect(() => {
      if (layoutReady) {
        setPaginaAtual(1);
        setTotalRegistrosPagina(registrosPagina);
        obterRegistros(1);
      }
    }, [layoutReady, filterForm, urlFetch]);

    if (isLoading) {
      return <Loading />;
    }
    return (
      <Fragment>
        <Table
          className={className}
          data={registros}
          columns={columns}
          columnsDetail={columnsDetail}
          dataDetailProperty={dataDetailProperty}
          showHeader={showHeader}
          showDetailHeader={showDetailHeader}
          singleRecordTable={singleRecordTable}
          uniqueHeaderSingleRecordTable={uniqueHeaderSingleRecordTable}
          classNameDetail={classNameDetail}
          enableHoverEffect={enableHoverEffect}
          enableHoverEffectDetail={enableHoverEffectDetail}
          onDoubleClick={onDoubleClick}
          onDoubleClickDetail={onDoubleClickDetail}
          culture={culture}
          timeZone={timeZone}
        />

        {totalRegistros > 0 ? (
          <Row className="g-0">
            <Column sm={6}>
              <div>
                <p className="mb-sm-0">
                  Mostrando de {(paginaAtual - 1) * totalRegistrosPagina + 1}{" "}
                  até{" "}
                  {(paginaAtual - 1) * registrosPagina + totalRegistrosPagina}{" "}
                  de {totalRegistros} registros
                </p>
              </div>
            </Column>
            <Column sm={6}>
              <div className="float-sm-end">
                <ul className="pagination pagination-rounded mb-sm-0">
                  <li
                    className={`page-item ${paginaAtual > 1 ? "" : "disabled"}`}
                  >
                    <Tooltip
                      position={TooltipPosition.Top}
                      text="Ir para a primeira página"
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={
                          paginaAtual > 1 ? () => carregarPagina(1) : null
                        }
                      >
                        <i className="mdi mdi-chevron-double-left"></i>
                      </a>
                    </Tooltip>
                  </li>
                  <li
                    className={`page-item ${paginaAtual > 1 ? "" : "disabled"}`}
                  >
                    <Tooltip
                      position={TooltipPosition.Top}
                      text="Ir para a página anterior"
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={
                          paginaAtual > 1
                            ? () => carregarPagina(paginaAtual - 1)
                            : null
                        }
                      >
                        <i className="mdi mdi-chevron-left"></i>
                      </a>
                    </Tooltip>
                  </li>

                  <li
                    className={`page-item ${
                      paginaAtual < totalPagina ? "" : "disabled"
                    }`}
                    onClick={
                      paginaAtual < totalPagina
                        ? () => carregarPagina(paginaAtual + 1)
                        : null
                    }
                  >
                    <Tooltip
                      position={TooltipPosition.Top}
                      text="Ir para a próxima página"
                    >
                      <a href="#" className="page-link">
                        <i className="mdi mdi-chevron-right"></i>
                      </a>
                    </Tooltip>
                  </li>
                  <li
                    className={`page-item ${
                      paginaAtual < totalPagina ? "" : "disabled"
                    }`}
                    onClick={
                      paginaAtual < totalPagina
                        ? () => carregarPagina(totalPagina)
                        : null
                    }
                  >
                    <Tooltip
                      position={TooltipPosition.Top}
                      text="Ir para a última página"
                    >
                      <a href="#" className="page-link">
                        <i className="mdi mdi-chevron-double-right"></i>
                      </a>
                    </Tooltip>
                  </li>
                </ul>
              </div>
            </Column>
          </Row>
        ) : null}
      </Fragment>
    );
  }
);

export default TableContainer;
