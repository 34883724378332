import React, { useState } from "react";
import Breadcrumbs from "../../Common/Breadcrumb";

interface PaginaLayoutProps {
  item: string;
  titulo: string;
  modulo: string;
  temFavorito?: boolean;
  children?: any;
  funcao?: number;
  podeRedimensionar?: boolean;
}

export const PaginaLayout = ({
  funcao,
  titulo,
  modulo,
  item,
  temFavorito = false,
  podeRedimensionar = false,
  children,
}: PaginaLayoutProps) => {
  document.title = `${titulo} | AET Master`;
  const [maximizado, setMaximizado] = useState(false);

  // Função para alternar o estado maximizado
  const handleRedimensionarClick = () => {
    setMaximizado((prev) => !prev);
  };

  return (
    <React.Fragment>
      <div className={`page-content ${maximizado ? "maximizado" : ""}`}>
        <div className="container-fluid">
          <Breadcrumbs
            funcao={funcao}
            modulo={modulo}
            titulo={titulo}
            item={item}
            temFavorito={temFavorito}
            podeRedimensionar={podeRedimensionar}
            maximizado={maximizado}
            onRedimensionarHandler={
              podeRedimensionar ? handleRedimensionarClick : null
            }
          />
          <div id="base-loading">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
