import React, { useEffect } from "react";

//import components
import Header from "../CommonForBoth/Header";
import Sidebar from "./Sidebar";
import Footer from "../CommonForBoth/Footer";

//constants

//import actions

//redux
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

interface LayoutProps {
  children: any;
}
const Layout = (props: LayoutProps) => {
  const selectLayoutProperties = createSelector(
    (state: any) => state.Layout,
    (layout) => ({
      leftSideBarType: layout.leftSideBarType,
      leftSideBarTheme: layout.leftSideBarTheme,
    })
  );
  // Inside your component
  const { leftSideBarType, leftSideBarTheme } = useSelector(
    selectLayoutProperties
  );

  const isMobile =
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ||
    window.innerWidth <= 768;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
