import React, {
  ReactNode,
  useCallback,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { ValidationMessage } from "../types";
import { mapToCssModules } from "../Utils/utils";
import classNames from "classnames";
import configSmartR from "src/configSmartR";
import gomelius from "src/api/gomelius";
import { ToastNotification, SwalAlert, Util } from "src/util";
import { GramaticaHelper } from "src/helpers";
import { useNavigate } from "react-router-dom";
import { ValidationResult } from "../ValidationResult";
import { Alert, AlertType } from "src/components";
import { TipoEdicaoRegistro } from "src/enums";
import { Position } from "src/util/ToastNotification";
import { Container, ContainerRef } from "../Container/Container";
import { EditorRef } from "../Editor/Editor";
import { flushSync } from "react-dom";

export interface ChildrenForm {
  urlGetList: string;
  urlGet?: string;
  urlPost?: string;
  urlPut?: string;
  urlDelete?: string;
  list: any[];
  loadRecordComplete?: boolean;
  skipSubmit?: boolean | (() => boolean);
  handleListOnLoad?: any;
  onBeforeSubmitHandler?: (list: any[]) => Promise<any[]>;
  onLoaded?: (list: any[]) => void;
  dispatch: React.Dispatch<React.SetStateAction<any[]>>;
  customValidation?: (record: any) => Promise<boolean>;
}

export interface AdditionalForm {
  urlGet: string;
  urlPost?: string;
  urlPut?: string;
  record: any;
  skipGet?: boolean | ((mainRecord: any) => boolean);
  skipSubmit?: boolean | (() => boolean);
  handleOnLoad?: any;
  onBeforeSubmitHandler?: (record: any) => Promise<any[]>;
  onLoaded?: (record: any) => void;
  dispatch: React.Dispatch<React.SetStateAction<any>>;
  customValidation?: (record: any) => Promise<boolean>;
}

export interface FileForm {
  fieldName?: string;
  urlPost: string;
  singleRequest?: boolean;
  skipSubmit?: boolean | (() => boolean);
  list: FileList;
  onBeforeSubmitHandler?: (list: FileList) => Promise<FileList>;
}

export interface FormProps {
  onSubmit?: (data: Record<string, any>) => void;
  className?: string;
  urlSubmit?: string;
  title?: string;
  routeBack?: string;
  askNewRecord?: boolean;
  showSuccessMessage?: boolean;
  id?: string;
  onSuccess?: any;
  onFail?: any;
  uniqueRecord?: boolean;
  manualHandleSuccess?: boolean;
  childrenFormList?: ChildrenForm[];
  childrenFileList?: FileForm[];
  additionalFormList?: AdditionalForm[];
  postSuccessMessage?: string;
  putSuccessMessage?: string;
  forceMethodPost?: boolean;
  contentType?: string;
  customValidation?: (values: Record<string, any>) => Promise<boolean>;
  beforeSubmitHandler?: (form: any) => Promise<any>;
  onSubmitStart?: () => void;
  onSubmitEnd?: () => void;
  children?: ReactNode;
}

export interface FormRef {
  getEditors: () => EditorRef[];
  validate: () => Promise<boolean>;
  getValidationResultList: () => Promise<ValidationResult[]>;
  submit: () => void;
}

export const Form = forwardRef<FormRef, FormProps>((props, ref) => {
  const formRef = useRef<HTMLFormElement>(null);
  const containerRef = useRef<ContainerRef>();
  const navigate = useNavigate();

  const {
    onSubmit,
    children,
    routeBack,
    title,
    askNewRecord = true,
    id,
    className,
    urlSubmit,
    onSuccess,
    onFail,
    uniqueRecord = false,
    manualHandleSuccess = true,
    showSuccessMessage = true,
    childrenFormList,
    childrenFileList,
    additionalFormList,
    postSuccessMessage,
    putSuccessMessage,
    forceMethodPost = false,
    contentType = "application/json",
    customValidation,
    beforeSubmitHandler,
    onSubmitStart,
    onSubmitEnd,
  } = props;

  const [errorMessage, setErrorMessage] = useState<string>("");

  const classes = mapToCssModules(
    classNames(className, configSmartR.classes.form)
  );

  function findInputByIdOrName(idOrName) {
    if (formRef.current) {
      const idOrNameLowercase = idOrName.toLowerCase();

      for (const field of formRef.current.elements) {
        if (
          field instanceof HTMLInputElement ||
          field instanceof HTMLTextAreaElement ||
          field instanceof HTMLSelectElement
        ) {
          const fieldIdLowercase = (field.id || "").toLowerCase();
          const fieldNameLowercase = (field.name || "").toLowerCase();

          if (
            fieldIdLowercase === idOrNameLowercase ||
            fieldNameLowercase === idOrNameLowercase
          ) {
            return field;
          }
        }
      }
    }
    return null;
  }
  const getEditors = (): EditorRef[] => {
    return containerRef.current.getEditors();
  };
  const getFormData = (): Record<string, any> => {
    const data: Record<string, any> = {};
    const editors = getEditors();
    for (const field of formRef.current.elements) {
      if (
        field instanceof HTMLInputElement ||
        field instanceof HTMLTextAreaElement ||
        field instanceof HTMLSelectElement ||
        (field instanceof HTMLElement && field.getAttribute("data-smarteditor"))
      ) {
        const fieldName = field.getAttribute("name");
        if (fieldName) {
          if (field.getAttribute("data-smarteditor")) {
            const ref = editors.find(
              (inputRef) =>
                inputRef.getSubmit() && inputRef.getName() === fieldName
            );
            if (ref) {
              if (ref.getSubmit && ref.getValue && ref.getSubmit()) {
                const value = ref.getValue();
                data[fieldName] = value;
              }
            } else {
              if ("value" in field) {
                data[fieldName] = field.value;
              }
            }
          } else {
            if ("value" in field) {
              data[fieldName] = field.value;
            }
          }
        }
      }
    }
    return data;
  };
  const getValidationFormResult = async (): Promise<ValidationResult[]> => {
    const editors = getEditors();
    const validationPromises = editors.map(async (ref) => {
      return await ref.validate();
    });

    const validationResults = await Promise.all(validationPromises);
    const flattenedResults = validationResults.flat();

    return flattenedResults;
  };

  const validateForm = useCallback(async () => {
    const listValidation = await getValidationFormResult();
    let canContinue = listValidation.every(
      (result) => result.Type !== ValidationMessage.Error
    );
    if (customValidation) {
      const data = getFormData();
      try {
        canContinue = await customValidation(data);
      } catch {
        canContinue = false;
      }
    }
    return canContinue;
  }, [customValidation]);
  const validateChildrenFormList = async () => {
    if (childrenFormList) {
      for (const children of childrenFormList) {
        if (children.customValidation) {
          try {
            const validationResults = await Promise.all(
              children.list.map(async (item) => {
                try {
                  return await children.customValidation(item);
                } catch {
                  return false;
                }
              })
            );

            if (!validationResults.every(Boolean)) {
              return false;
            }
          } catch (error) {
            console.error("Erro na validação dos formulários:", error);
            return false;
          }
        }
      }
    }
    return true;
  };

  const validateAdditionalFormList = async () => {
    if (additionalFormList) {
      for (const additionalForm of additionalFormList) {
        if (additionalForm.customValidation) {
          try {
            const validationResult = await additionalForm.customValidation(
              additionalForm.record
            );

            if (!validationResult) {
              return false;
            }
          } catch (error) {
            console.error("Erro na validação do formulário adicional:", error);
            return false;
          }
        }
      }
    }
    return true;
  };

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      const data = getFormData();
      const editors = getEditors();
      const validationResults = await getValidationFormResult();
      let canContinue =
        validationResults.every(
          (result) => result.Type !== ValidationMessage.Error
        ) &&
        (await validateForm()) &&
        (await validateChildrenFormList()) &&
        (await validateAdditionalFormList());

      if (canContinue) {
        if (!urlSubmit && onSubmit) {
          onSubmit(data);
        } else if (urlSubmit) {
          const promises = [];
          let formData = Util.removeNullAndEmptyFields(data);
          if (beforeSubmitHandler) {
            formData = await beforeSubmitHandler(formData);
          }

          if (formData) {
            if (onSubmitStart) {
              onSubmitStart();
            }
            const additionalFormListSubmit = await Promise.all(
              (additionalFormList || [])
                .filter((additionalForm: AdditionalForm) => {
                  const skipSubmit = additionalForm.skipSubmit;
                  return typeof skipSubmit === "function"
                    ? !skipSubmit()
                    : !skipSubmit;
                })
                .map(async (additionalForm: AdditionalForm) => {
                  let record = additionalForm.record;
                  if (additionalForm.onBeforeSubmitHandler) {
                    try {
                      const modifiedRecord =
                        await additionalForm.onBeforeSubmitHandler(
                          additionalForm.record
                        );
                      record = modifiedRecord;
                    } catch (error) {
                      console.error(
                        "Error on executing onBeforeSubmitHandler on additional form:",
                        error
                      );
                    }
                  }

                  return {
                    urlPost: additionalForm.urlPost,
                    urlPut: additionalForm.urlPut,
                    record: record,
                  };
                })
            );

            const childrenListSubmit = await Promise.all(
              (childrenFormList || [])
                .filter((childrenForm) => {
                  const skipSubmit = childrenForm.skipSubmit;
                  return typeof skipSubmit === "function"
                    ? !skipSubmit()
                    : !skipSubmit;
                })
                .map(async (childrenForm) => {
                  let listToSubmit = childrenForm.list;

                  if (childrenForm.onBeforeSubmitHandler) {
                    try {
                      const modifiedList =
                        await childrenForm.onBeforeSubmitHandler(
                          childrenForm.list
                        );
                      listToSubmit = modifiedList;
                    } catch (error) {
                      console.error(
                        "Error on executing onBeforeSubmitHandler on child form:",
                        error
                      );
                    }
                  }

                  return {
                    urlPost: childrenForm.urlPost,
                    urlPut: childrenForm.urlPut,
                    urlDelete: childrenForm.urlDelete,
                    list: listToSubmit,
                  };
                })
            );

            const fileListSubmit = await Promise.all(
              (childrenFileList || [])
                .filter((childrenFile) => {
                  const skipSubmit = childrenFile.skipSubmit;
                  return typeof skipSubmit === "function"
                    ? !skipSubmit()
                    : !skipSubmit;
                })
                .map(async (childrenFile) => {
                  let listToSubmit: FileList = childrenFile.list;

                  if (childrenFile.onBeforeSubmitHandler) {
                    try {
                      const modifiedList =
                        await childrenFile.onBeforeSubmitHandler(
                          childrenFile.list
                        );
                      listToSubmit = modifiedList;
                    } catch (error) {
                      console.error(
                        "Error on executing onBeforeSubmitHandler:",
                        error
                      );
                    }
                  }

                  return {
                    urlPost: childrenFile.urlPost,
                    fieldName: childrenFile.fieldName,
                    singleRequest: childrenFile.singleRequest,
                    list: listToSubmit,
                  };
                })
            );

            if ((!id && !uniqueRecord) || forceMethodPost) {
              gomelius
                .post(formData, urlSubmit, contentType)
                .then(async function (resp: any) {
                  const id = resp.id;

                  additionalFormListSubmit?.forEach((additionalForm) => {
                    promises.push(
                      gomelius.postChildren(
                        id,
                        Util.removeNullAndEmptyFields(additionalForm.record),
                        additionalForm.urlPost
                      )
                    );
                  });

                  childrenListSubmit?.forEach((childrenForm) => {
                    childrenForm.list.forEach((record) => {
                      promises.push(
                        gomelius.postChildren(
                          id,
                          Util.removeNullAndEmptyFields(record),
                          childrenForm.urlPost
                        )
                      );
                    });
                  });

                  fileListSubmit?.forEach((childrenFile) => {
                    if (childrenFile.list && childrenFile.list.length > 0) {
                      if (childrenFile.singleRequest) {
                        const formData = new FormData();

                        for (
                          let index = 0;
                          index < childrenFile.list.length;
                          index++
                        ) {
                          const file = childrenFile.list[index];
                          formData.append(childrenFile.fieldName, file);
                        }
                        promises.push(
                          gomelius.postChildrenForm(
                            id,
                            formData,
                            childrenFile.urlPost
                          )
                        );
                      } else {
                      }
                      for (
                        let index = 0;
                        index < childrenFile.list.length;
                        index++
                      ) {
                        const file = childrenFile.list[index];
                        const formData = new FormData();
                        formData.append(childrenFile.fieldName, file);
                        promises.push(
                          gomelius.postChildren(
                            id,
                            formData,
                            childrenFile.urlPost
                          )
                        );
                      }
                    }
                  });

                  const results = await Promise.all(promises);

                  if (!onSuccess || (onSuccess && !manualHandleSuccess)) {
                    if (onSuccess && !manualHandleSuccess) {
                      onSuccess(resp);
                    }
                    if (showSuccessMessage) {
                      let message = `${GramaticaHelper.obterArtigoGramatical(
                        title
                      ).toUpperCase()} <strong> ${title.toLowerCase()}</strong> foi salvo com sucesso!${
                        askNewRecord
                          ? "<br><br>Deseja inserir um novo registro?"
                          : ""
                      }`;
                      if (postSuccessMessage) {
                        message = postSuccessMessage;
                      }
                      if (askNewRecord) {
                        SwalAlert.confirmar(title, message)
                          .then(function () {
                            window.location.reload();
                          })
                          .catch(function () {
                            navigate(`/${routeBack}`);
                          });
                      } else {
                        SwalAlert.sucesso(title, message).then(function () {
                          if (routeBack) {
                            navigate(`/${routeBack}`);
                          }
                        });
                      }
                    }
                  } else {
                    if (resp.sucesso) {
                      onSuccess(resp);
                    } else {
                      onFail(resp);
                    }
                  }
                })
                .catch(function (resp: any) {
                  if (!onFail) {
                    if (resp.resultados) {
                      const group = new Map();

                      resp.resultados.forEach((resultado) => {
                        if (!group.has(resultado.id)) {
                          group.set(resultado.id, []);
                        }
                        group.get(resultado.id).push(resultado);

                        const ref = editors.find(
                          (inputRef) =>
                            inputRef.getName().toUpperCase() ===
                            resultado.id.toUpperCase()
                        );

                        if (ref && ref.getTitle) {
                          ToastNotification.error(
                            resultado.mensagem,
                            ref.getTitle(),
                            Position.TOP_RIGHT,
                            function () {
                              if (resultado.id) {
                                const input = findInputByIdOrName(resultado.id);
                                if (input) {
                                  input.focus();
                                }
                              }
                            }
                          );
                        } else {
                          ToastNotification.error(resultado.mensagem);
                        }
                      });

                      group.forEach((resultados, id) => {
                        const ref = editors.find(
                          (inputRef) =>
                            inputRef.getName().toUpperCase() ===
                            id.toUpperCase()
                        );
                        if (ref && ref.setErrorList) {
                          const name = ref.getName();
                          const value = ref.getValue();
                          const title = ref.getTitle();
                          let listError: ValidationResult[] = [];
                          resultados.forEach((item) => {
                            listError.push(
                              new ValidationResult(
                                name,
                                title,
                                ValidationMessage.Error,
                                item.mensagem,
                                value
                              )
                            );
                          });
                          if (listError.length > 0) {
                            ref.setErrorList(listError);
                          }
                        }
                      });
                    }

                    if (
                      (!resp.resultados || resp.resultados.length === 0) &&
                      resp.mensagem
                    ) {
                      setErrorMessage(resp.mensagem);
                    }
                  } else {
                    onFail(resp);
                  }
                });
            } else {
              gomelius
                .put(id, formData, urlSubmit, contentType)
                .then(async function (resp: any) {
                  additionalFormListSubmit?.forEach((additionalForm) => {
                    promises.push(
                      gomelius.put(
                        id,
                        Util.removeNullAndEmptyFields(additionalForm.record),
                        additionalForm.urlPost
                      )
                    );
                  });

                  childrenListSubmit?.forEach((childrenForm) => {
                    childrenForm.list.forEach((record) => {
                      if (record.registro) {
                        switch (record.registro) {
                          case TipoEdicaoRegistro.novo:
                            {
                              promises.push(
                                gomelius.postChildren(
                                  id,
                                  Util.removeNullAndEmptyFields(record),
                                  childrenForm.urlPost
                                )
                              );
                            }
                            break;
                          case TipoEdicaoRegistro.editar:
                            {
                              promises.push(
                                gomelius.putChildren(
                                  id,
                                  record.id,
                                  Util.removeNullAndEmptyFields(record),
                                  childrenForm.urlPut
                                )
                              );
                            }
                            break;
                          case TipoEdicaoRegistro.delete:
                            {
                              promises.push(
                                gomelius.deleteChildren(
                                  id,
                                  record.id,
                                  childrenForm.urlDelete
                                )
                              );
                            }
                            break;
                        }
                      }
                    });
                  });
                  fileListSubmit?.forEach((childrenFile) => {
                    if (childrenFile.list && childrenFile.list.length > 0) {
                      if (childrenFile.singleRequest) {
                        const formData = new FormData();

                        for (
                          let index = 0;
                          index < childrenFile.list.length;
                          index++
                        ) {
                          const file = childrenFile.list[index];
                          formData.append(childrenFile.fieldName, file);
                        }
                        promises.push(
                          gomelius.postChildren(
                            id,
                            formData,
                            childrenFile.urlPost
                          )
                        );
                      } else {
                      }
                      for (
                        let index = 0;
                        index < childrenFile.list.length;
                        index++
                      ) {
                        const file = childrenFile.list[index];
                        const formData = new FormData();
                        formData.append(childrenFile.fieldName, file);
                        promises.push(
                          gomelius.postChildrenForm(
                            id,
                            formData,
                            childrenFile.urlPost
                          )
                        );
                      }
                    }
                  });
                  const results = await Promise.all(promises);

                  if (onSubmitEnd) {
                    onSubmitEnd();
                  }

                  if (!onSuccess || (onSuccess && !manualHandleSuccess)) {
                    if (onSuccess && !manualHandleSuccess) {
                      onSuccess(resp);
                    }
                    if (showSuccessMessage) {
                      let message = `${GramaticaHelper.obterArtigoGramatical(
                        title
                      ).toUpperCase()} <strong> ${title.toLowerCase()}</strong> foi atualizado com sucesso!`;
                      if (putSuccessMessage) {
                        message = putSuccessMessage;
                      }
                      SwalAlert.sucesso(title, message).then(function () {
                        if (routeBack) {
                          navigate(`/${routeBack}`);
                        }
                      });
                    }
                  } else {
                    if (resp.sucesso) {
                      onSuccess(resp);
                    } else {
                      onFail(resp);
                    }
                  }
                })
                .catch(function (resp: any) {
                  if (onSubmitEnd) {
                    onSubmitEnd();
                  }
                  if (!onFail) {
                    if (resp.resultados) {
                      const group = new Map();

                      resp.resultados.forEach((resultado) => {
                        if (!group.has(resultado.id)) {
                          group.set(resultado.id, []);
                        }
                        group.get(resultado.id).push(resultado);
                        const ref = editors.find(
                          (inputRef) =>
                            inputRef.getName().toUpperCase() ===
                            resultado.id.toUpperCase()
                        );

                        if (ref && ref.getTitle) {
                          ToastNotification.error(
                            resultado.mensagem,
                            ref.getTitle(),
                            Position.TOP_RIGHT,
                            function () {
                              if (resultado.id) {
                                const input = findInputByIdOrName(resultado.id);
                                if (input) {
                                  input.focus();
                                }
                              }
                            }
                          );
                        } else {
                          ToastNotification.error(resultado.mensagem);
                        }
                      });

                      group.forEach((resultados, id) => {
                        const ref = editors.find(
                          (inputRef) =>
                            inputRef.getName().toUpperCase() ===
                            id.toUpperCase()
                        );
                        if (ref && ref.setErrorList) {
                          const name = ref.getName();
                          const value = ref.getValue();
                          const title = ref.getTitle();
                          let listError: ValidationResult[] = [];
                          resultados.forEach((item) => {
                            listError.push(
                              new ValidationResult(
                                name,
                                title,
                                ValidationMessage.Error,
                                item.mensagem,
                                value
                              )
                            );
                          });
                          if (listError.length > 0) {
                            ref.setErrorList(listError);
                          }
                        }
                      });
                    }

                    if (
                      (!resp.resultados || resp.resultados.length === 0) &&
                      resp.mensagem
                    ) {
                      ToastNotification.warning(
                        resp.mensagem,
                        title,
                        Position.BOTTOM_CENTER
                      );
                      setErrorMessage(resp.mensagem);
                    }
                  } else {
                    onFail(resp);
                  }
                });
            }
          }
        }
      } else {
        validationResults.forEach((result) => {
          ToastNotification.error(
            result.Message,
            result.Title,
            Position.TOP_RIGHT,
            function () {
              if (result.Name) {
                const input = findInputByIdOrName(result.Name);
                if (input) {
                  input.focus();
                }
              }
            }
          );
        });
      }
    },
    [
      onSubmit,
      beforeSubmitHandler,
      onSuccess,
      onFail,
      onSubmitStart,
      onSubmitEnd,
      childrenFormList,
      childrenFileList,
      additionalFormList,
    ]
  );

  useImperativeHandle(ref, () => ({
    getEditors: getEditors,
    validate: validateForm,
    getValidationResultList: getValidationFormResult,
    submit: () => {
      if (formRef.current) {
        formRef.current.dispatchEvent(
          new Event("submit", { bubbles: true, cancelable: true })
        );
      }
    },
  }));

  return (
    <form className={classes} onSubmit={handleSubmit} ref={formRef}>
      {errorMessage && <Alert type={AlertType.Danger}>{errorMessage}</Alert>}
      <Container ref={containerRef}>{children}</Container>
    </form>
  );
});
