import React, { useState } from "react";
import "./index.scss";
import { Tooltip } from "src/components";

export enum DirectionType {
  Left = 1,
  Right = 2,
}

interface TransferListProps<T> {
  availableItems: T[];
  selectedItems: T[];
  onTransfer: (items: T[], direction: DirectionType) => void;
  descriptionProp?: keyof T;
  availableTitle?: string;
  selectedTitle?: string;
  toolTipAddSelected?: string;
  toolTipAddAll?: string;
  toolTipRemoveSelected?: string;
  toolTipRemoveAll?: string;
}

export const TransferList = <T,>({
  availableItems,
  selectedItems,
  onTransfer,
  descriptionProp,
  availableTitle = "Available",
  selectedTitle = "Selecteds",
  toolTipAddSelected = "Click to add selected item",
  toolTipAddAll = "Click to add all items",
  toolTipRemoveSelected = "Click to remove selected item",
  toolTipRemoveAll = "Click to remove all item",
}: TransferListProps<T>) => {
  const [selectedAvailable, setSelectedAvailable] = useState<T[]>([]);
  const [selectedSelected, setSelectedSelected] = useState<T[]>([]);

  const getItemDescription = (item: T) => {
    if (descriptionProp) {
      return item[descriptionProp] as unknown as string;
    }
    return item as unknown as string;
  };

  const handleTransferToRight = () => {
    onTransfer(selectedAvailable, DirectionType.Right);
    setSelectedAvailable([]);
  };

  const handleTransferToLeft = () => {
    onTransfer(selectedSelected, DirectionType.Left);
    setSelectedSelected([]);
  };

  const handleSelectAvailable = (item: T, isCtrlPressed: boolean) => {
    if (isCtrlPressed) {
      // Se Ctrl for pressionado, adicionar ou remover o item da seleção
      setSelectedAvailable((prev) =>
        prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
      );
      setSelectedSelected([]); // Limpa a seleção na lista oposta
    } else {
      // Se Ctrl não for pressionado, limpar seleção anterior e selecionar apenas o item clicado
      setSelectedAvailable([item]);
      setSelectedSelected([]); // Limpa a seleção na lista oposta
    }
  };

  const handleSelectSelected = (item: T, isCtrlPressed: boolean) => {
    if (isCtrlPressed) {
      // Se Ctrl for pressionado, adicionar ou remover o item da seleção
      setSelectedSelected((prev) =>
        prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
      );
      setSelectedAvailable([]); // Limpa a seleção na lista oposta
    } else {
      // Se Ctrl não for pressionado, limpar seleção anterior e selecionar apenas o item clicado
      setSelectedSelected([item]);
      setSelectedAvailable([]); // Limpa a seleção na lista oposta
    }
  };

  const handleTransferAllToRight = () => {
    onTransfer(availableItems, DirectionType.Right);
    setSelectedAvailable([]);
  };

  const handleTransferAllToLeft = () => {
    onTransfer(selectedItems, DirectionType.Left);
    setSelectedSelected([]);
  };

  const handleItemClick = (
    item: T,
    listType: "available" | "selected",
    event: React.MouseEvent
  ) => {
    const isCtrlPressed = event.ctrlKey || event.metaKey; // Detecta se Ctrl ou Command (para Mac) está pressionado
    if (listType === "available") {
      handleSelectAvailable(item, isCtrlPressed);
    } else {
      handleSelectSelected(item, isCtrlPressed);
    }
  };

  return (
    <div className="transfer-list-container row mt-2 mb-2">
      <div className="col-md-5">
        <h5 className="text-center">{availableTitle}</h5>
        <ul className="list-group">
          {availableItems.map((item, index) => (
            <li
              key={index}
              onClick={(e) => handleItemClick(item, "available", e)}
              className={`list-group-item ${
                selectedAvailable.includes(item) ? "active" : ""
              }`}
            >
              {getItemDescription(item)}
            </li>
          ))}
        </ul>
      </div>

      {/* Controles do meio */}
      <div className="col-md-2 transfer-controls d-flex flex-column align-items-center justify-content-center">
        <Tooltip text={toolTipAddSelected}>
          <button
            className="btn btn-primary mb-2"
            onClick={handleTransferToRight}
            type="button"
          >
            &gt;
          </button>
        </Tooltip>
        <Tooltip text={toolTipRemoveSelected}>
          <button
            className="btn btn-primary mb-2"
            onClick={handleTransferToLeft}
            type="button"
          >
            &lt;
          </button>
        </Tooltip>
        <Tooltip text={toolTipAddAll}>
          <button
            className="btn btn-secondary mb-2"
            onClick={handleTransferAllToRight}
            type="button"
          >
            &gt;&gt;
          </button>
        </Tooltip>
        <Tooltip text={toolTipRemoveAll}>
          <button
            className="btn btn-secondary mb-2"
            onClick={handleTransferAllToLeft}
            type="button"
          >
            &lt;&lt;
          </button>
        </Tooltip>
      </div>

      <div className="col-md-5">
        <h5 className="text-center">{selectedTitle}</h5>
        <ul className="list-group">
          {selectedItems.map((item, index) => (
            <li
              key={index}
              onClick={(e) => handleItemClick(item, "selected", e)}
              className={`list-group-item ${
                selectedSelected.includes(item) ? "active" : ""
              }`}
            >
              {getItemDescription(item)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
