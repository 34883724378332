import { Navigate, Outlet } from "react-router-dom";

import naoAutenticadoRoutes from "./routes/naoAutenticadoRoutes";
import autenticadoRoutes from "./routes/autenticadoRoutes";

import AuthGuard from "./auth/AuthGuard";
import Layout from "./components/Layout";
import LayoutAutenticado from "./components/LayoutAutenticado";
import LayoutNaoAutenticado from "./components/LayoutNaoAutenticado";
import { LayoutProvider } from "./contexts/layoutContext";

const routes = [
  {
    element: (
      <LayoutProvider>
        <AuthGuard>
          <Layout>
            <LayoutAutenticado>
              <Outlet />
            </LayoutAutenticado>
          </Layout>
        </AuthGuard>
      </LayoutProvider>
    ),
    children: [...autenticadoRoutes],
  },
  {
    element: (
      <LayoutProvider>
        <Layout>
          <LayoutNaoAutenticado>
            <Outlet />
          </LayoutNaoAutenticado>
        </Layout>
      </LayoutProvider>
    ),
    children: [...naoAutenticadoRoutes],
  },
  { path: "/", element: <Navigate to="/Dashboard" /> },
  { path: "*", element: <Navigate to="/404" /> },
];

export default routes;
