import { DropdownOptionListOptionPropTypes } from "../DropdownOptionList";
import { useCallback, useEffect, useState } from "react";
import gomelius from "src/api/gomelius";
import { PermissaoMapa } from "src/PermissaoMapa";
import "./index.scss";
import { Tooltip } from "src/components";
export interface ButtomOptionPropTypes {
  toolTip?: string;
  iconClassName?: string;
  text?: string;
  link?: string;
  visible?: boolean;
  enabled?: boolean;
  codigoPermissao?: number;
  onClickHandler?: any;
}
interface ButtonListPropTypes {
  options?: ButtomOptionPropTypes[];
  className?: string;
}

export function ButtomList(props: ButtonListPropTypes) {
  const { options, className } = props;

  const [opcoesFiltrada, setOpcoesFiltrada] = useState<
    DropdownOptionListOptionPropTypes[]
  >([]);
  const handleItemClick = useCallback(
    (event: React.MouseEvent, option: DropdownOptionListOptionPropTypes) => {
      if (option.onClickHandler) {
        option.onClickHandler(event);
      } else if (option.link) {
        window.location.href = option.link;
      }
    },
    []
  );
  // Verifica permissões ao montar o componente
  useEffect(() => {
    const usuarioAdministrador = gomelius.usuarioAdministrador();

    // Verificação para permissões de ações principais
    const permissoesUsuario = gomelius.obterPermissoes();

    // Filtra as ações extras com base nas permissões
    const acoesExtraFiltrado = (options || []).map((acao) => {
      // Se a opção já tem visible como false, não será exibida
      if (acao.visible === false) {
        return {
          ...acao,
          visible: false,
        };
      }

      if (!usuarioAdministrador) {
        // Se tem código de permissão, verifica a permissão do usuário
        if (acao.codigoPermissao && acao.codigoPermissao > 0) {
          const temPermissao = permissoesUsuario.includes(
            PermissaoMapa.obterPosicaoBit(acao.codigoPermissao)
          );

          return {
            ...acao,
            visible: temPermissao,
          };
        }
      }

      // Se o usuário for administrador, respeita o valor de `visible` se já estiver definido
      return {
        ...acao,
        visible: acao.visible !== undefined ? acao.visible : true,
      };
    });

    // Atualiza o estado com o array filtrado
    setOpcoesFiltrada(acoesExtraFiltrado);
  }, [options]);

  // Renderização principal
  return (
    <>
      <div className={`button-actions ${className || ""}`}>
        {opcoesFiltrada.map(
          (option, index) =>
            option.visible &&
            (option.toolTip || option.text ? (
              <Tooltip key={index} text={option.toolTip || option.text}>
                <span onClick={(event) => handleItemClick(event, option)}>
                  <i className={option.iconClassName}></i>
                </span>
              </Tooltip>
            ) : (
              <span onClick={(event) => handleItemClick(event, option)}>
                <i className={option.iconClassName}></i>
              </span>
            ))
        )}
      </div>
    </>
  );
}
