import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BroadcastProvider } from "./contexts";

const store = configureStore({ reducer: rootReducer, devTools: true });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <React.Fragment>
    <BroadcastProvider>
      <BrowserRouter>
        <App />
        <ToastContainer />
      </BrowserRouter>
      </BroadcastProvider>
    </React.Fragment>
  </Provider>
);