const configTable = {
  timeZone: "Central Brazilian Standard Time",
  culture: "pt-BR",
  sourceValueProperty: "id",
  sourceDescriptionProperty: "descricao",
  sourceBadgeProperty: "badge",
  badgeClassName: "badge bg-link rounded-pill font-size-12",
};

export default configTable;
