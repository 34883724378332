import React from "react";
import "./index.scss";
import { SideType, TimelineType } from "./const";
import { TimelineItem } from "./item";
import { TimelineSeparator } from "./separator";

interface TimelineProps {
  type: TimelineType;
  children: any;
  className?: string;
}

export const Timeline = ({
  type = TimelineType.LEFT,
  children,
  className,
}: TimelineProps) => {
  let containerClass = `verti-timeline ${className || ""}`;

  if (type === TimelineType.LEFT) {
    containerClass += " left-timeline";
  } else if (type === TimelineType.CENTER) {
    containerClass += "center-timeline justify-content-center";
  } else {
    containerClass += "horizontal-timeline";
  }

  return (
    <div className={containerClass}>
      {React.Children.map(children, (child, index) => {
        // Verifica se o componente é do tipo TimelineItem
        if (child.type === TimelineItem) {
          return React.cloneElement(child, {
            side:
              type === TimelineType.LEFT
                ? SideType.LEFT
                : index % 2 === 0
                ? SideType.LEFT
                : SideType.RIGHT,
          });
        }

        // Se for do tipo TimelineSeparator e o tipo da timeline for CENTER, não passa "side"
        if (child.type === TimelineSeparator && type === TimelineType.CENTER) {
          return React.cloneElement(child, {});
        }

        return child;
      })}
    </div>
  );
};
