import { useDispatch } from "react-redux";
import { SwalAlert } from "src/util";
import { removerServidor, atualizarRegistro } from "src/slices/thunks";
import { ButtomList, DropdownOptionList } from "src/components";
import { DropdownOptionListOptionPropTypes } from "../DropdownOptionList";
import { GramaticaHelper } from "src/helpers";
import { useEffect, useState } from "react";
import gomelius from "src/api/gomelius";
import { PermissaoMapa } from "src/PermissaoMapa";

interface AcaoRegistroListaPropTypes {
  registro?: any;
  titulo: string;
  descricao: string;
  routeEdit?: string;
  origem: string;
  onEditarClick?: any;
  onDeletarClick?: any;
  onInativarClick?: any;
  onReativarClick?: any;
  podeEditar?: boolean;
  podeInativar?: boolean;
  podeReativar?: boolean;
  podeDeletar?: boolean;
  mensagemInativar?: string;
  mensagemReativar?: string;
  mensagemDeletar?: string;
  codigoPermissaoEditar?: number;
  codigoPermissaoInativar?: number;
  codigoPermissaoReativar?: number;
  codigoPermissaoDeletar?: number;
  acoesExtra?: DropdownOptionListOptionPropTypes[];
  renderMode?: "dropdown" | "button"; // Nova propriedade
}

export function AcaoRegistroLista(props: AcaoRegistroListaPropTypes) {
  const {
    registro,
    titulo,
    descricao,
    routeEdit,
    origem,
    onDeletarClick: onDeleteClick,
    onEditarClick: onEditClick,
    onReativarClick,
    onInativarClick,
    podeEditar = true,
    podeInativar = true,
    podeReativar = true,
    podeDeletar = true,
    mensagemDeletar,
    mensagemInativar,
    mensagemReativar,
    acoesExtra = [],
    codigoPermissaoEditar,
    codigoPermissaoInativar,
    codigoPermissaoReativar,
    codigoPermissaoDeletar,
    renderMode = "dropdown", // Valor padrão será "dropdown"
  } = props;

  const dispatch = useDispatch();
  const [permissaoEditar, setPermissaoEditar] = useState(true);
  const [permissaoInativar, setPermissaoInativar] = useState(true);
  const [permissaoReativar, setPermissaoReativar] = useState(true);
  const [permissaoDeletar, setPermissaoDeletar] = useState(true);
  const [acoesExtraFiltrado, setAcoesExtraFiltrado] = useState<
    DropdownOptionListOptionPropTypes[]
  >([]);

  const handleDeleteClick = () => {
    SwalAlert.confirmar(
      "Atenção",
      mensagemDeletar ??
        `Tem certeza que deseja <strong>deletar</strong> ${GramaticaHelper.obterArtigoGramatical(
          titulo
        )} ${titulo.toLocaleLowerCase()} abaixo<br><br><strong>${descricao}</strong>`
    )
      .then(() => {
        if (onDeleteClick) {
          onDeleteClick(registro.id);
        } else {
          dispatch(
            removerServidor({
              origem: origem,
              titulo: titulo,
              id: registro.id,
            })
          );
        }
      })
      .catch(() => {});
  };

  const handleInativarClick = () => {
    SwalAlert.confirmar(
      "Atenção",
      mensagemInativar ??
        `Tem certeza que deseja <strong>inativar</strong> ${GramaticaHelper.obterArtigoGramatical(
          titulo
        )} ${titulo.toLocaleLowerCase()} abaixo<br><br><strong>${descricao}</strong>`
    )
      .then(() => {
        if (onInativarClick) {
          onInativarClick(registro.id);
        } else {
          dispatch(
            atualizarRegistro({
              origem: origem,
              titulo: titulo,
              id: registro.id,
              status: 2,
            })
          );
        }
      })
      .catch(() => {});
  };

  const handleReativarClick = () => {
    SwalAlert.confirmar(
      "Atenção",
      mensagemReativar ??
        `Tem certeza que deseja <strong>reativar</strong> ${GramaticaHelper.obterArtigoGramatical(
          titulo
        )} ${titulo.toLocaleLowerCase()} abaixo<br><br><strong>${descricao}</strong>`
    )
      .then(() => {
        if (onReativarClick) {
          onReativarClick(registro.id);
        } else {
          dispatch(
            atualizarRegistro({
              origem: origem,
              titulo: titulo,
              id: registro.id,
              status: 1,
            })
          );
        }
      })
      .catch(() => {});
  };

  // Verifica permissões ao montar o componente
  useEffect(() => {
    const usuarioAdministrador = gomelius.usuarioAdministrador();

    // Verificação para permissões de ações principais
    if (!usuarioAdministrador) {
      const permissoesUsuario = gomelius.obterPermissoes();

      if (codigoPermissaoEditar && codigoPermissaoEditar > 0) {
        setPermissaoEditar(
          permissoesUsuario.includes(
            PermissaoMapa.obterPosicaoBit(codigoPermissaoEditar)
          )
        );
      }

      if (codigoPermissaoInativar && codigoPermissaoInativar > 0) {
        setPermissaoInativar(
          permissoesUsuario.includes(
            PermissaoMapa.obterPosicaoBit(codigoPermissaoInativar)
          )
        );
      }

      if (codigoPermissaoReativar && codigoPermissaoReativar > 0) {
        setPermissaoReativar(
          permissoesUsuario.includes(
            PermissaoMapa.obterPosicaoBit(codigoPermissaoReativar)
          )
        );
      }

      if (codigoPermissaoDeletar && codigoPermissaoDeletar > 0) {
        setPermissaoDeletar(
          permissoesUsuario.includes(
            PermissaoMapa.obterPosicaoBit(codigoPermissaoDeletar)
          )
        );
      }

      // Filtra as ações extras com base nas permissões
      const acoesExtraFiltrado = (acoesExtra || []).map((acao) => {
        if (
          !usuarioAdministrador &&
          acao.codigoPermissao &&
          acao.codigoPermissao > 0
        ) {
          const temPermissao = permissoesUsuario.includes(
            PermissaoMapa.obterPosicaoBit(acao.codigoPermissao)
          );

          return {
            ...acao,
            visible: temPermissao,
          };
        }
        // Se o usuário for administrador ou não tiver um código de permissão, mantém o valor original
        return {
          ...acao,
          visible: acao.visible !== undefined ? acao.visible : true, // Se já tem visible, mantém, se não, define como true
        };
      });

      // Atualiza o estado com o array filtrado
      setAcoesExtraFiltrado(acoesExtraFiltrado);
    } else {
      setAcoesExtraFiltrado(acoesExtra);
    }
  }, []);

  // Renderização principal
  return (
    <>
      {renderMode === "dropdown" ? (
        <DropdownOptionList
          options={[
            {
              text: "Editar",
              iconClassName: "mdi mdi-file-document-edit-outline text-success",
              toolTip: "Clique para editar este regisro!",
              link:
                !onEditClick && routeEdit
                  ? `/${routeEdit}/${registro.id}`
                  : null,
              onClickHandler: onEditClick,
              visible: podeEditar && permissaoEditar && registro.situacao === 1,
            },
            {
              text: "Reativar",
              iconClassName: "mdi mdi-check-box-outline text-primary",
              onClickHandler: handleReativarClick,
              visible:
                podeReativar && permissaoReativar && registro.situacao === 2,
            },
            {
              text: "Inativar",
              iconClassName: "mdi mdi-block-helper text-warning",
              onClickHandler: handleInativarClick,
              visible:
                podeInativar && permissaoInativar && registro.situacao === 1,
            },
            {
              text: "Excluir",
              iconClassName: "mdi mdi-trash-can-outline text-danger",
              onClickHandler: handleDeleteClick,
              visible: podeDeletar && permissaoDeletar,
            },
            ...acoesExtraFiltrado,
          ]}
        />
      ) : (
        <ButtomList
          options={[
            {
              text: "Editar",
              iconClassName: "mdi mdi-file-document-edit-outline text-success",
              link:
                !onEditClick && routeEdit
                  ? `/${routeEdit}/${registro.id}`
                  : null,
              onClickHandler: onEditClick,
              visible: podeEditar && permissaoEditar && registro.situacao === 1,
            },
            {
              text: "Reativar",
              iconClassName: "mdi mdi-check-box-outline text-primary",
              onClickHandler: handleReativarClick,
              visible:
                podeReativar && permissaoReativar && registro.situacao === 2,
            },
            {
              text: "Inativar",
              iconClassName: "mdi mdi-block-helper text-warning",
              onClickHandler: handleInativarClick,
              visible:
                podeInativar && permissaoInativar && registro.situacao === 1,
            },
            {
              text: "Excluir",
              iconClassName: "mdi mdi-trash-can-outline text-danger",
              onClickHandler: handleDeleteClick,
              visible: podeDeletar && permissaoDeletar,
            },
            ...acoesExtraFiltrado,
          ]}
        />
      )}
    </>
  );
}
