import gomelius from "src/api/gomelius";
import { ToastNotification } from "src/util";

export const RelatorioHelper = {
  visualizarComParametro: (id, parametros) => {
    visualizarRelatorio(parametros, `Relatorio/${id}`);
  },

  visualizarSemParametro: (id) => {
    visualizarRelatorio( null, `Relatorio/${id}`);
  },

  visualizarDireto: (url) => {
    visualizarRelatorio( null, url);
  },
};

// Função auxiliar para visualizar o relatório
const visualizarRelatorio = (parametros, endpoint) => {
  const BASE_REPORT_URL = process.env.REACT_APP_REPORT_BASE;

  gomelius.post(parametros, endpoint).then((resposta: any) => {
    if (resposta.sucesso) {
      const url = `${BASE_REPORT_URL}Visualizar/${resposta.id}/${
        resposta.dadosAdicionais
      }?access_token=${gomelius.getToken()}`;
      abrirJanelaPopup(url);
    } else {
      ToastNotification.warning(
        "Não foi possível preparar este relatório neste momento"
      );
    }
  });
};

// Função auxiliar para abrir a janela de popup
const abrirJanelaPopup = (url) => {
  const width = window.screen.availWidth;
  const height = window.screen.availHeight;

  const params = [
    "scrollbars=yes",
    "fullscreen=yes",
    "resizable=no",
    `width=${width}`,
    `height=${height}`,
  ].join(",");

  const win = window.open(url, "popupWindow", params);

  if (win) win.focus();
};
