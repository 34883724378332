export const FormatadoHelper = {
  ObterCPF: (cpf: string): string => {
    // Remove todos os caracteres que não são números
    const cleaned = cpf.replace(/\D/g, "");

    // Verifica se o CPF tem o tamanho correto
    if (cleaned.length !== 11) {
      return "";
    }

    // Formata o CPF no padrão 000.000.000-00
    return cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  },
  ObterCNPJ: (cnpj: string): string => {
    // Remove todos os caracteres que não são números
    const cleaned = cnpj.replace(/\D/g, "");

    // Verifica se o CNPJ tem o tamanho correto
    if (cleaned.length !== 14) {
      return "";
    }

    // Formata o CNPJ no padrão 00.000.000/0000-00
    return cleaned.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  },
};
