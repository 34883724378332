import React, { useEffect, useState } from "react";

//actions
import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
  changelayoutMode,
} from "../../slices/thunks";

//constants
import { layoutTypes } from "../../constants/layout";

//redux
import { useSelector, useDispatch } from "react-redux";

//components
import Navbar from "./Navbar";
import Header from "../CommonForBoth/Header";
// import Header from "./Header";
import Footer from "../CommonForBoth/Footer";
import { createSelector } from "reselect";
import { AdminUtil } from "src/util";

interface LayoutProps {
  children: any;
}

const Layout = (props: LayoutProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    var config = AdminUtil.obterConfiguracao();
    dispatch(changeLayout(layoutTypes.HORIZONTAL));
    dispatch(changelayoutMode(config.Tema));
    dispatch(changeLayoutWidth(config.LarguraBarraLateral));
    dispatch(changeTopbarTheme(config.BarraSuperiorTema));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Navbar menuOpen={false} />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;
