import React, { useEffect } from "react";
import "./index.scss";

interface LoadingProps {
  height?: string;
  fontSize?: string;
  text?: string;
  position?: "top" | "bottom" | "center" | "fullscreen";
}

export const Loading = ({
  height = "70vh",
  fontSize = "5vw",
  text = "Carregando...",
  position = "center",
}: LoadingProps) => {
  // Define a posição com base na prop
  const positionClass = `loading-position-${position}`;

  return (
    <div
      className={`loading-base ${positionClass}`}
      style={{ height: position === "fullscreen" ? "100vh" : height }}
    >
      <div className="bg-overlay bg-white"></div>
      <div className="loading-container">
        <div className="loading-text" style={{ fontSize }}>
          {text.split("").map((letter, index) => (
            <span key={index}>{letter}</span>
          ))}
        </div>
      </div>
    </div>
  );
};
