export const AdminUtil = {
  salvarConfiguracao: (configuracao) => {
    const json = JSON.stringify(configuracao);
    localStorage.setItem("Configuracao", json);
  },
  obterConfiguracao: () => {
    const json = localStorage.getItem("Configuracao");
    let configuracao = {
      BarraLateralTema: "light",
      BarraSuperiorTema: "light",
      LarguraBarraLateral: "fluid",
      Layout: "vertical",
      Scrollable: false,
      Tema: "light",
      TipoBarraLateral: "lg",
    };
    if (json) {
      configuracao = { ...configuracao, ...JSON.parse(json) };
    }
    return configuracao;
  },
  salvarListaFavorito: (lista) => {
    const json = JSON.stringify(lista);
    localStorage.setItem("Favoritos", json);
  },
  adicionarFavorito: (codigo) => {
    const json = localStorage.getItem("Favoritos");
    let lista = [];
    if (json) {
      lista = JSON.parse(json);
      if (!lista.includes(codigo)) {
        lista.push(codigo);
      }
    } else {
      lista = [codigo];
    }
    AdminUtil.salvarListaFavorito(lista);
  },
  obterFavoritos: () => {
    const json = localStorage.getItem("Favoritos");
    let lista = [];
    if (json) {
      lista = JSON.parse(json);
    }
    return lista;
  },
  removerFavorito: (codigo) => {
    const json = localStorage.getItem("Favoritos");
    let lista = [];
    if (json) {
      lista = JSON.parse(json);
      if (lista.includes(codigo)) {
        lista = lista.filter((filter) => filter !== codigo);
      }
    }
    AdminUtil.salvarListaFavorito(lista);
  },
  funcaoFavorita: (codigo) => {
    const json = localStorage.getItem("Favoritos");
    if (json) {
      const lista = JSON.parse(json);
      return lista.includes(codigo);
    }
    return false;
  },
  obterFusoHorario: () => {
    let fusoHorario = localStorage.getItem("fusoHorario");
    if (!fusoHorario) {
      fusoHorario = "Central Brazilian Standard Time";
    }
    return fusoHorario;
  },
  salvarFusoHorario: (fusoHorario) => {
    if (!fusoHorario) {
      fusoHorario = "Central Brazilian Standard Time";
    }
    localStorage.setItem("fusoHorario", fusoHorario);
  },
};
