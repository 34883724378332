import React from "react";

//import withRouter
import { withRouter} from "src/hocs";

// users
//redux
import { Link } from "react-router-dom";
import { DropdownToggle, DropdownMenu, Dropdown } from "src/components";
import { Icon } from "@ailibs/feather-react-ts";
import SimpleBar from "simplebar-react";
import { gerarMenuFavoritos } from "src/components/CommonForBoth/Menu";
import gomelius from "src/api/gomelius";

const FavoritoMenu = (props: any) => {
  const permissoesUsuario = gomelius.obterPermissoes();
  const usuarioAdministrador = gomelius.usuarioAdministrador();

  const listaMenu = gerarMenuFavoritos(permissoesUsuario, usuarioAdministrador);

  if (listaMenu.length === 0) {
    return null; // Não renderiza se não houver favoritos
  }
  return (
    <React.Fragment>
      <Dropdown>
        <DropdownToggle className="btn header-item noti-icon d-none d-lg-block">
          <Icon name="star" className="icon-sm" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <SimpleBar style={{ height: "270px" }}>
            {listaMenu.map((modulo) => (
              <React.Fragment key={modulo.id}>
                <h5 className="text-center favorito-grupo-titulo">
                  <i className={`mdi ${modulo.icon}`}></i>
                  {modulo.label}
                </h5>
                {modulo.funcoes.map((item, key) => (
                  <React.Fragment key={key}>
                    <Link
                      className="notification-item text-reset"
                      to={item.link}
                    >
                      <div className="d-flex border-bottom align-items-center">
                        <div className="flex-shrink-0">
                          <i className={`mdi mdi-24px ${item.icon}`}></i>
                        </div>
                        <div className="flex-grow-1 ms-4">
                          <h6 className="mb-0">{item.label}</h6>
                          <p className="font-size-12 text-muted mb-0">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(FavoritoMenu);
