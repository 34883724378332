export type TableColumnProps = {
  header?: string;
  accessor?: string;
  type?: ColumnType;
  headerClassName?: string;
  contentClassName?: string;
  headerAlign?: AlignType;
  contentAlign?: AlignType;
  format?: string;
  hideMinOrDefaultValue?: boolean;
  disableFilters?: boolean;
  prefix?: string;
  suffix?: string;
  mask?: string | ((value: any) => string);
  sourceList?: any[];
  sourceValueProperty?: string;
  sourceDescriptionProperty?: string;
  sourceBadgeProperty?: string;
  displayBadge?: boolean;
  width?: string;
  renderCell?: (data?: any) => any;
  onDoubleClick?: (
    rowIndex: number,
    colIndex: number,
    value: any,
    rowData: any
  ) => void;
};
export interface TableRef {
  getSelectedRows: () => any[];
  selectRow: (rowIndex: number) => void;
  deselectRow: (rowIndex: number) => void;
  selectAll: () => void;
  deselectAll: () => void;
}
export interface TableProps {
  columns: TableColumnProps[];
  columnsDetail?: TableColumnProps[];
  showHeader?: boolean;
  showDetailHeader?: boolean;
  enableHoverEffect?: boolean;
  enableHoverEffectDetail?: boolean;
  singleRecordTable?: boolean;
  uniqueHeaderSingleRecordTable?: boolean;
  data?: any[];
  dataDetailProperty?: string;
  className?: any;
  classNameDetail?: any;
  timeZone?: string;
  culture?: string;
  selection?: SelectionType;
  enableSelectAll?: boolean;
  onCheckedChange?: (rowData: any, index: number, checked: boolean) => void;
  onCheckedAllChange?: (checked: boolean) => void;
  onDoubleClick?: (rowData: any, index: number) => void;
  onDoubleClickDetail?: (
    rowData: any,
    rowDataParent: any,
    rowIndex: number,
    parentRowIndex: number
  ) => void;
}
export enum SelectionType {
  NONE = 1,
  SINGLE = 1,
  MULTIPLE = 2,
  CHECKBOX = 3,
}
export enum ColumnType {
  STRING = 1,
  FLOAT = 2,
  DATE = 3,
  DATETIME = 4,
  INT = 5,
  BOOLEAN = 6,
  TEMPLATE = 7,
}

export enum AlignType {
  START = 1,
  CENTER = 2,
  END = 3,
}
