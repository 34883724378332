import { v4 as uuidv4 } from "uuid";
export const Util = {
  numberOnly: function (text) {
    return text.replace(/\D/g, "");
  },
  newUUID: function () {
    return uuidv4();
  },
  applyMask: function (input, mask) {
    if (!mask || !input) return "";
    let maskedValue = "";
    let maskIndex = 0;

    for (let i = 0; i < input.length; i++) {
      if (maskIndex >= mask.length) break;

      const maskChar = mask[maskIndex];

      if (maskChar === "0") {
        if (/\d/.test(input[i])) {
          maskedValue += input[i];
          maskIndex++;
        }
      } else if (maskChar === "9") {
        if (/\d/.test(input[i])) {
          maskedValue += input[i];
          maskIndex++;
        }
      } else if (maskChar === "A") {
        if (/[a-zA-Z0-9]/.test(input[i])) {
          maskedValue += input[i];
          maskIndex++;
        }
      } else if (maskChar === "S") {
        if (/[a-zA-Z]/.test(input[i])) {
          maskedValue += input[i];
          maskIndex++;
        }
      } else {
        maskedValue += maskChar;
        maskIndex++;
        if (input[i] !== maskChar) {
          i--;
        }
      }
    }

    return maskedValue;
  },
  removeNullAndEmptyFields: function (obj) {
    const result = {};
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (value !== null && value !== "") {
        result[key] = value;
      }
    });
    return result;
  },
  round2Decimal: function (value: number) {
    return Math.round(value * 100) / 100;
  },
  formatDecimal: function (
    number,
    prefix = "",
    suffix = "",
    thousandsSeparator = ".",
    decimalSeparator = ","
  ) {
    const numberString = (number ?? 0).toFixed(2);

    const parts = numberString.split(".");
    let wholeNumber = parts[0];
    let decimalPart = parts[1];

    if (thousandsSeparator) {
      wholeNumber = wholeNumber.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        thousandsSeparator
      );
    }

    return prefix + wholeNumber + decimalSeparator + decimalPart + suffix;
  },
  formatDecimalWithouDecimals: function (
    number,
    prefix = "",
    suffix = "",
    thousandsSeparator = "."
  ) {
    const numberString = (number ?? 0).toFixed(0);

    if (thousandsSeparator) {
      return (
        prefix +
        numberString.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator) +
        suffix
      );
    } else {
      return prefix + numberString + suffix;
    }
  },

  getScreenSize: function () {
    const windowWidth = window.innerWidth;
    let size = "xl";

    if (windowWidth < 576) {
      size = "xs";
    } else if (windowWidth >= 576 && windowWidth < 768) {
      size = "sm"; // Small
    } else if (windowWidth >= 768 && windowWidth < 992) {
      size = "md"; // Medium
    } else if (windowWidth >= 992 && windowWidth < 1200) {
      size = "lg"; // Large
    } else if (windowWidth >= 1200 && windowWidth < 1400) {
      size = "xl"; // Extra large
    } else {
      size = "xxl"; // Extra Extra large
    }
    return size;
  },
};
