import React, { useState } from "react";
import { Link } from "react-router-dom";

//import drawer

//import component
import RightSidebar from "./RightSidebar";
import NotificationDropdown from "./TopbarDropdown/NotificationDropdown";
import ProfileMenu from "./TopbarDropdown/ProfileMenu";

//import images
import logoSmDark from "../../assets/images/logo-sm-dark.png";
import logoSmLight from "../../assets/images/logo-sm-light.png";
import logoDark from "../../assets/images/logo-sm-dark.png";
import logoLight from "../../assets/images/logo-sm-light.png";

//Import Icons
import { OffCanvas } from "src/components";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import FavoritoMenu from "./TopbarDropdown/FavoritoMenu";
import Search from "./TopbarDropdown/Search";

const Header = (props: any) => {
  const dadosAuthSelector = createSelector(
    (state: any) => state.autenticacao,
    (autenticacao: any) => ({
      usuario: autenticacao.usuario,
    })
  );
  const { usuario } = useSelector(dadosAuthSelector);

  const selectLayoutProperties = createSelector(
    (state: any) => state.Layout,
    (layout: any) => ({
      showSidebar: layout.showSidebar,
    })
  );
  // Inside your component
  const { showSidebar } = useSelector(selectLayoutProperties);

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    if (window.innerWidth < 768) {
      body.classList.toggle("sidebar-enable");
    }
    document.body.setAttribute("data-sidebar-size", "lg");
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/Dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSmDark} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="60" />
                </span>
              </Link>

              <Link to="/Dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSmLight} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="60" />
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            {/* colocar menu de fatoritos ou outros aqui */}
          </div>
          <div className="d-flex fs-4 fw-semibold text-muted d-none d-lg-block">
            {usuario.cliente}
          </div>
          <div className="d-flex">
            <Search />

            <FavoritoMenu />
            <NotificationDropdown />

            <ProfileMenu />
          </div>
        </div>
      </header>
      <OffCanvas isOpen={showSidebar} direction={"end"}>
        <RightSidebar />
      </OffCanvas>
    </React.Fragment>
  );
};

export default Header;
