import { forwardRef, Fragment, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { mapToCssModules } from "src/SmartR/Utils/utils";
import { Util } from "src/util";
import {
  AlignType,
  ColumnType,
  SelectionType,
  TableColumnProps,
  TableProps,
  TableRef,
} from "./types";
import { timeZoneMap } from "./timezone";
import { DateHelper } from "src/helpers";
import configTable from "src/configTable";
import { flushSync } from "react-dom";

export const Table = forwardRef<TableRef, TableProps>(
  (
    {
      columns,
      columnsDetail,
      dataDetailProperty,
      showHeader = true,
      showDetailHeader = true,
      enableHoverEffect = true,
      enableHoverEffectDetail = true,
      singleRecordTable = false,
      uniqueHeaderSingleRecordTable = false,
      onDoubleClick,
      onDoubleClickDetail,
      onCheckedChange,
      onCheckedAllChange,
      timeZone = configTable.timeZone,
      culture = configTable.culture,
      selection = SelectionType.NONE,
      enableSelectAll = false,
      data = [],
      className,
      classNameDetail,
    },
    ref
  ) => {
    const [sortedColumn, setSortedColumn] = useState<TableColumnProps>(null);
    const [isSortedDesc, setIsSortedDesc] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    useImperativeHandle(ref, () => ({
      getSelectedRows: () => {
        return selectedRows.map((index) => data[index]);
      },
      selectRow: (rowIndex: number) => {
        if (!selectedRows.includes(rowIndex)) {
          setSelectedRows((prevSelected) => [...prevSelected, rowIndex]);
        }
      },
      deselectRow: (rowIndex: number) => {
        setSelectedRows((prevSelected) =>
          prevSelected.filter((index) => index !== rowIndex)
        );
      },
      selectAll: () => {
        const allRows = data.map((_, index) => index);
        setSelectedRows(allRows);
        if (onCheckedAllChange) {
          onCheckedAllChange(true);
        }
      },
      deselectAll: () => {
        setSelectedRows([]);
        if (onCheckedAllChange) {
          onCheckedAllChange(false);
        }
      },
    }));
    const handleSelectAll = (event) => {
      if (event.target.checked) {
        const allRows = data.map((_, index) => index);
        flushSync(() => {
          setSelectedRows(allRows);
        });
        if (onCheckedAllChange) {
          onCheckedAllChange(true);
        }
      } else {
        flushSync(() => {
          setSelectedRows([]);
        });
        if (onCheckedAllChange) {
          onCheckedAllChange(false);
        }
      }
    };

    const handleSelect = (event, rowIndex) => {
      if (event.target.checked) {
        flushSync(() => {
          setSelectedRows((prevSelected) => [...prevSelected, rowIndex]);
        });
        if (onCheckedChange) {
          onCheckedChange(data[rowIndex], rowIndex, true); // Dispara o evento ao selecionar
        }
      } else {
        flushSync(() => {
          setSelectedRows((prevSelected) =>
            prevSelected.filter((index) => index !== rowIndex)
          );
        });
        if (onCheckedChange) {
          onCheckedChange(data[rowIndex], rowIndex, false); // Dispara o evento ao desmarcar
        }
      }
    };

    const formatDate = (date, formatOptions = {}, timeZone) => {
      const ianaTimeZone = timeZoneMap[timeZone] || timeZone;
      const utcDateString =
        typeof date === "string" && date.length > 10 && !date.endsWith("Z")
          ? `${date}Z`
          : date;
      return new Intl.DateTimeFormat(culture, {
        ...formatOptions,
        timeZone: ianaTimeZone,
      }).format(new Date(utcDateString));
    };

    const getAlignClassName = (type: AlignType) => {
      if (type) {
        switch (type) {
          case AlignType.CENTER:
            return "text-center";
          case AlignType.END:
            return "text-end";
        }
      }
      return null;
    };
    const getDefaultValue = (type?: ColumnType) => {
      if (type) {
        switch (type) {
          case ColumnType.FLOAT:
          case ColumnType.INT:
            return 0;
          case ColumnType.DATE:
            return "0001-01-01";
        }
      }
      return "";
    };
    const generateSortingIndicator = (column: TableColumnProps) => {
      if (sortedColumn && sortedColumn.accessor === column.accessor) {
        return isSortedDesc ? " 🔽" : " 🔼";
      }
      return null;
    };

    const handleSort = (column: TableColumnProps) => {
      if (column.disableFilters) return;
      if (sortedColumn && sortedColumn.accessor === column.accessor) {
        setIsSortedDesc(!isSortedDesc);
      } else {
        setSortedColumn(column);
        setIsSortedDesc(false);
      }
    };

    const sortedData = [...data].sort((a, b) => {
      if (!sortedColumn) return 0;

      const valueA = a.hasOwnProperty(sortedColumn.accessor)
        ? a[sortedColumn.accessor]
        : getDefaultValue(sortedColumn.type);
      const valueB = b.hasOwnProperty(sortedColumn.accessor)
        ? b[sortedColumn.accessor]
        : getDefaultValue(sortedColumn.type);

      if (sortedColumn.type === ColumnType.DATE) {
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);
        if (dateA < dateB) return isSortedDesc ? 1 : -1;
        if (dateA > dateB) return isSortedDesc ? -1 : 1;
        return 0;
      }

      if (typeof valueA === "number" && typeof valueB === "number") {
        if (valueA < valueB) return isSortedDesc ? 1 : -1;
        if (valueA > valueB) return isSortedDesc ? -1 : 1;
        return 0;
      }

      if (valueA < valueB) return isSortedDesc ? 1 : -1;
      if (valueA > valueB) return isSortedDesc ? -1 : 1;
      return 0;
    });

    const isMinOrDefaultValue = (value: any, type?: ColumnType) => {
      if (type) {
        switch (type) {
          case ColumnType.FLOAT:
          case ColumnType.INT:
            return parseInt(value) === 0;
          case ColumnType.DATE:
            return (
              value === "0001-01-01" ||
              (value instanceof Date &&
                value.toISOString().startsWith("0001-01-01"))
            );
        }
      }
      return false;
    };
    const getColumnValue = (column: TableColumnProps, record: any): any => {
      let value = column.accessor ? record[column.accessor] : null;
      if (value === null || value === undefined) {
        value = getDefaultValue(column.type);
      }
      return value;
    };
    const renderCellValue = (
      record,
      column: TableColumnProps,
      row: number,
      parent: any = null,
      parentRow?: number
    ) => {
      let value = getColumnValue(column, record);
      if (
        column.hideMinOrDefaultValue &&
        isMinOrDefaultValue(value, column.type)
      ) {
        value = null;
      }

      if (column.renderCell) {
        return column.renderCell({
          record,
          value,
          row,
          parent,
          parentRow,
        });
      }

      if (value !== undefined && value !== null) {
        if (column.sourceList && Array.isArray(column.sourceList)) {
          const sourceValueProperty =
            column.sourceValueProperty ?? configTable.sourceValueProperty;
          const sourceDescriptionProperty =
            column.sourceDescriptionProperty ??
            configTable.sourceDescriptionProperty;

          if (sourceValueProperty && sourceDescriptionProperty) {
            const foundItem = column.sourceList.find(
              (item) => item[sourceValueProperty] === value
            );

            if (foundItem) {
              value = foundItem[sourceDescriptionProperty];
              if (column.displayBadge) {
                const sourceBadgeProperty =
                  column.sourceBadgeProperty ?? configTable.sourceBadgeProperty;
                if (sourceBadgeProperty) {
                  const badgeClasses = mapToCssModules(
                    classNames(
                      configTable.badgeClassName,
                      foundItem[sourceBadgeProperty]
                    )
                  );
                  return <div className={badgeClasses}>{value}</div>;
                }
              }
              return value;
            }
          }
        }

        if (column.format) {
          const formatOptions = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          };

          if (column.format.match(/[cdfnp]/i)) {
            const decimalPlaces =
              column.format.length > 1
                ? parseInt(column.format.substring(1))
                : 0;
            formatOptions.minimumFractionDigits = decimalPlaces;
            formatOptions.maximumFractionDigits = decimalPlaces;
          }

          switch (column.format.charAt(0)) {
            case "d":
              return formatDate(
                value,
                { year: "numeric", month: "numeric", day: "numeric" },
                timeZone
              );
            case "D":
              return formatDate(
                value,
                {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                },
                timeZone
              );
            case "t":
              return formatDate(
                value,
                { hour: "2-digit", minute: "2-digit" },
                timeZone
              );
            case "T":
              return formatDate(
                value,
                {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                },
                timeZone
              );
            case "c":
            case "C":
              return parseFloat(value).toLocaleString(culture, {
                style: "currency",
                currency: "BRL",
                ...formatOptions,
              });
            case "f":
            case "F":
              return parseFloat(value).toLocaleString(culture, formatOptions);
            case "n":
            case "N":
              return parseInt(value, 10).toLocaleString(culture, formatOptions);
            case "p":
            case "P":
              return `${parseFloat(value).toFixed(2)}%`;
            default:
              return value;
          }
        }

        if (column.mask) {
          return typeof column.mask === "string"
            ? Util.applyMask(value, column.mask)
            : column.mask(record);
        }
        switch (column.type) {
          case ColumnType.FLOAT:
            return parseFloat(value).toLocaleString(culture, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          case ColumnType.DATE:
            return DateHelper.getFormattedDate(value, timeZone);
          case ColumnType.DATETIME:
            return DateHelper.getFormattedDateTime(value);
          case ColumnType.INT:
            return parseInt(value, 10);
          default: {
            return value;
          }
        }
      }
    };

    const classes = mapToCssModules(
      classNames(
        className,
        "table table-bordered",
        enableHoverEffect ? "table-hover" : ""
      )
    );

    const classesDetail = mapToCssModules(
      classNames(
        classNameDetail,
        "table table-bordered",
        enableHoverEffectDetail ? "table-hover" : ""
      )
    );

    const renderHeader = () => {
      return (
        <>
          <thead className="table-light table-nowrap">
            <tr role="row">
              {selection === SelectionType.CHECKBOX && (
                <th
                  role="columnheader"
                  className={`header-checkbox text-center`}
                >
                  {enableSelectAll && (
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      aria-label="Select All"
                    />
                  )}
                </th>
              )}
              {columns.map((column, index) => (
                <th
                  key={`th-header-${index}`}
                  role="columnheader"
                  className={classNames(
                    column.headerClassName,
                    getAlignClassName(column.headerAlign),
                    {
                      "header-filter": !column.disableFilters,
                    }
                  )}
                  style={{
                    ...(column.width ? { width: column.width } : {}),
                  }}
                  onClick={!singleRecordTable ? () => handleSort(column) : null}
                >
                  {column.header}
                  {!singleRecordTable && generateSortingIndicator(column)}
                </th>
              ))}
            </tr>
          </thead>
        </>
      );
    };

    const renderTableHeader = () => {
      return (
        <>
          <div className="table-responsive react-table">
            <table role="table" className={classes}>
              {renderHeader()}
            </table>
          </div>
        </>
      );
    };

    const renderTable = (data) => {
      const handleRowDoubleClick = (
        event: React.MouseEvent<HTMLTableRowElement>,
        rowData: any,
        rowIndex: number
      ) => {
        if (onDoubleClick) {
          onDoubleClick(rowData, rowIndex);
        }
      };
      const handleRowDoubleClickDetail = (
        event: React.MouseEvent<HTMLTableRowElement>,
        rowData: any,
        rowDataParent: any,
        rowIndex: number,
        rowIndexParent: number
      ) => {
        if (onDoubleClickDetail) {
          onDoubleClickDetail(rowData, rowDataParent, rowIndex, rowIndexParent);
        }
      };
      const handleCellDoubleClick = (
        rowIndex: number,
        colIndex: number,
        rowData: any,
        column: TableColumnProps
      ) => {
        if (column.onDoubleClick) {
          column.onDoubleClick(
            rowIndex,
            colIndex,
            getColumnValue(column, rowData),
            rowData
          );
        }
      };
      return (
        <>
          <div className="table-responsive react-table">
            <table role="table" className={classes}>
              {showHeader && !uniqueHeaderSingleRecordTable && renderHeader()}

              <tbody>
                {data.map((record, rowIndex) => (
                  <Fragment key={`fragement-${rowIndex}`}>
                    <tr
                      key={`tr-${rowIndex}`}
                      role="row"
                      onDoubleClick={(event) =>
                        handleRowDoubleClick(event, record, rowIndex)
                      }
                    >
                      {selection === SelectionType.CHECKBOX && (
                        <td role="cell" className="cell-checkbox text-center">
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(rowIndex)}
                            onChange={(event) => handleSelect(event, rowIndex)}
                            aria-label="Select"
                          />
                        </td>
                      )}
                      {columns.map((column, colIndex) => (
                        <td
                          key={`td-${colIndex}`}
                          role="cell"
                          className={classNames(
                            column.contentClassName,
                            getAlignClassName(column.contentAlign)
                          )}
                          style={{
                            ...((!showHeader ||
                              (showHeader && uniqueHeaderSingleRecordTable)) &&
                            column.width
                              ? { width: column.width }
                              : {}),
                          }}
                          onDoubleClick={(event) =>
                            handleCellDoubleClick(
                              rowIndex,
                              colIndex,
                              record,
                              column
                            )
                          }
                        >
                          {renderCellValue(record, column, rowIndex)}
                        </td>
                      ))}
                    </tr>
                    {columnsDetail && columnsDetail.length > 0 && (
                      <tr
                        key={`tr-detail-${rowIndex}`}
                        role="row"
                        className="tr-detail"
                      >
                        <td colSpan={columns.length}>
                          <table role="table" className={classesDetail}>
                            {showDetailHeader && (
                              <thead className="table-light table-nowrap">
                                <tr key={`tr-detail-${rowIndex}`} role="row">
                                  {columnsDetail.map((columnDetail, index) => (
                                    <th
                                      key={`td-detail-${index}`}
                                      role="columnheader"
                                      className={classNames(
                                        columnDetail.headerClassName,
                                        getAlignClassName(
                                          columnDetail.headerAlign
                                        ),
                                        {
                                          "header-filter":
                                            !columnDetail.disableFilters,
                                        }
                                      )}
                                      style={{
                                        ...(columnDetail.width
                                          ? { width: columnDetail.width }
                                          : {}),
                                      }}
                                      onClick={() => handleSort(columnDetail)}
                                    >
                                      {columnDetail.header}
                                      {generateSortingIndicator(columnDetail)}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                            )}

                            <tbody>
                              {record[dataDetailProperty].map(
                                (recordDetail, rowDetailIndex) => (
                                  <tr
                                    key={rowDetailIndex}
                                    role="row"
                                    onDoubleClick={(event) =>
                                      handleRowDoubleClickDetail(
                                        event,
                                        recordDetail,
                                        record,
                                        rowDetailIndex,
                                        rowIndex
                                      )
                                    }
                                  >
                                    {columnsDetail.map(
                                      (columnDetail, colDetailIndex) => (
                                        <td
                                          key={colDetailIndex}
                                          role="cell"
                                          className={classNames(
                                            columnDetail.contentClassName,
                                            getAlignClassName(
                                              columnDetail.contentAlign
                                            )
                                          )}
                                          style={{
                                            ...((!showHeader ||
                                              (showHeader &&
                                                uniqueHeaderSingleRecordTable)) &&
                                            columnDetail.width
                                              ? { width: columnDetail.width }
                                              : {}),
                                          }}
                                        >
                                          {renderCellValue(
                                            recordDetail,
                                            columnDetail,
                                            rowDetailIndex,
                                            record,
                                            rowIndex
                                          )}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    };

    return !singleRecordTable ? (
      <Fragment>{renderTable(sortedData)}</Fragment>
    ) : (
      <Fragment>
        {uniqueHeaderSingleRecordTable && renderTableHeader()}
        {sortedData.map((record, index) => (
          <Fragment key={`fra-${index}`}>{renderTable([record])}</Fragment>
        ))}
      </Fragment>
    );
  }
);
