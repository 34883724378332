import { useState, useEffect, useRef, ChangeEvent } from "react";
import configSmartR from "src/configSmartR";
import { EditorInput } from "../EditorInput/EditorInput";
import { Input } from "../types";
import { mapToCssModules } from "../Utils/utils";
import classNames from "classnames";

interface SelectPropTypes {
  options: any[];
  optionsId: string;
  optionsDescription: string;
  optionsGetDescription?: (option: any) => string;
  optionsFirstSelected: boolean;
  optionsNoneSelectedValue: any;
  optionsNoneSelecteText: string;
  optionsFilter: boolean;
  optionsMultiple: boolean;
  optionsLimiteDescriptionSelected: number;
  optionsMultipleSeparatorValue: string;
  optionsMultipleSeparatorDescription: string;
  placeholder?: string;
  searchPlaceholder?: string;
  value: any;
  editorAttributes: Record<string, any>;
  onBlurEvent: (event: React.FocusEvent<HTMLInputElement>) => Promise<void>;
  onChangeEvent: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  inputRef: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  className?: string;
}

interface CustomSelectState {
  isOpen: boolean;
  searchText: string;
}

const Select = (props: SelectPropTypes) => {
  const {
    options,
    optionsId,
    optionsDescription,
    optionsGetDescription,
    optionsFirstSelected,
    optionsFilter,
    optionsMultiple,
    optionsLimiteDescriptionSelected,
    optionsMultipleSeparatorValue,
    optionsMultipleSeparatorDescription,
    optionsNoneSelectedValue,
    optionsNoneSelecteText,
    editorAttributes,
    placeholder,
    searchPlaceholder,
    value,
    onBlurEvent,
    onChangeEvent,
    inputRef,
    className,
    ...attributes
  } = props;

  const [filterState, setFilterState] = useState<CustomSelectState>({
    isOpen: false,
    searchText: "",
  });
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef(null);
  const { isOpen, searchText } = filterState;

  let innerChildren = null;
  let editorValue = value;

  let editorDescription = null;
  let selecteds = [];
  let selectLabelText = configSmartR.texts.optionsMultipleSelectAll;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setFilterState((prevState) => ({
          ...prevState,
          searchText: "",
          isOpen: false,
        }));
      }
    };

    if (filterState.isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      if (containerRef.current) {
        const width = containerRef.current.getBoundingClientRect().width;
        setContainerWidth(width);
      }
      if (optionsFilter) {
        searchInputRef.current.focus();
      }
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterState.isOpen]);

  const toggleDropdown = () => {
    setFilterState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterState((prevState) => ({
      ...prevState,
      searchText: e.target.value,
    }));
  };

  const selectAllClick = (select: boolean) => {
    if (select) {
      selecteds = options.map((item) => String(item[optionsId]));
    } else {
      selecteds = [];
    }
    editorValue = selecteds.join(optionsMultipleSeparatorValue);
    inputRef.current.value = editorValue;

    onChangeEvent({
      target: inputRef.current,
    } as React.ChangeEvent<HTMLInputElement>);
  };
  const handleOptionClick = (option: any, event: React.MouseEvent) => {
    event.stopPropagation();
    editorValue = option[optionsId];
    editorDescription = option[optionsDescription];
    setFilterState((prevState) => ({
      ...prevState,
      isOpen: !optionsMultiple ? false : true,
      searchText: "",
    }));
    let clickedValue = option[optionsId];
    if (optionsMultiple) {
      if (!selecteds.includes(String(clickedValue))) {
        selecteds.push(String(clickedValue));
      } else {
        selecteds = selecteds.filter(
          (Filter) => Filter !== String(clickedValue)
        );
      }
      clickedValue = selecteds.join(optionsMultipleSeparatorValue);
    }

    inputRef.current.value = clickedValue;

    onChangeEvent({
      target: inputRef.current,
    } as React.ChangeEvent<HTMLInputElement>);
  };

  if (options) {
    const dataSource =
      options && typeof options[Symbol.iterator] === "function"
        ? [...options]
        : [];
    if (!optionsMultiple) {
      if (!optionsFirstSelected) {
        let noneSelectOption = {};
        noneSelectOption[optionsId] = optionsNoneSelectedValue;
        noneSelectOption[optionsDescription] = optionsNoneSelecteText;
        dataSource.unshift(noneSelectOption);
        if (!editorValue) {
          editorValue = optionsNoneSelectedValue;
        }
      } else {
        if (!editorValue && dataSource.length > 0) {
          editorValue = dataSource[0][optionsId];
        }
      }
      const option = dataSource.find(
        (Filter) => Filter[optionsId] === editorValue
      );
      if (option) {
        editorDescription = option[optionsDescription];
      }
    } else {
      if (editorValue) {
        if (!Array.isArray(editorValue)) {
          if (typeof editorValue === "string") {
            if (editorValue.includes(optionsMultipleSeparatorValue)) {
              selecteds = String(editorValue).split(
                optionsMultipleSeparatorValue
              );
            } else {
              const option = dataSource.find(
                (Filter) => Filter[optionsId] === editorValue
              );
              if (option) {
                selecteds = [editorValue];
              } else {
                const editorNumber = parseInt(editorValue);
                if (!isNaN(editorNumber)) {
                  dataSource.forEach((item) => {
                    if (
                      (editorNumber & parseInt(item[optionsId])) ===
                      parseInt(item[optionsId])
                    ) {
                      selecteds.push(String(item[optionsId]));
                    }
                  });
                }
              }
            }
          } else if (typeof editorValue === "number") {
            dataSource.forEach((item) => {
              if (
                (editorValue & parseInt(item[optionsId])) ===
                parseInt(item[optionsId])
              ) {
                selecteds.push(String(item[optionsId]));
              }
            });
          }
        } else {
          selecteds = editorValue.map(String);
        }
      } else {
        selecteds = [];
      }

      if (selecteds.length === 0) {
        selectLabelText = configSmartR.texts.optionsMultipleSelectAll;
        editorDescription = configSmartR.texts.optionsMultipleNoneSelected;
      } else if (selecteds.length === dataSource.length) {
        selectLabelText = configSmartR.texts.optionsMultipleSelectNone;
      }
      if (selecteds.length > 0) {
        if (selecteds.length <= optionsLimiteDescriptionSelected) {
          const descriptions = options
            .filter((item) => selecteds.includes(String(item[optionsId])))
            .map((item) => item[optionsDescription]);

          editorDescription = descriptions.join(
            configSmartR.optionsMultipleSeparatorDescription
          );
        } else {
          editorDescription = `${selecteds.length} ${configSmartR.texts.optionsMultipleSelected}`;
        }
      }
    }
    const items = dataSource.map((option, index) => {
      option[optionsDescription] =
        !optionsGetDescription ||
        (index === 0 && optionsGetDescription && !optionsFirstSelected)
          ? option[optionsDescription]
          : optionsGetDescription(option);
      return option;
    });
    innerChildren = items
      .filter((option) =>
        option[optionsDescription]
          .toLowerCase()
          .includes(searchText.toLowerCase())
      )
      .map((option, index) => {
        const optionText = option[optionsDescription];
        const startIndex = optionText
          .toLowerCase()
          .indexOf(searchText.toLowerCase());
        const endIndex = startIndex + searchText.length;

        return (
          <div
            key={index}
            className={configSmartR.classes.select.option}
            onClick={(e) => handleOptionClick(option, e)}
          >
            {optionsMultiple ? (
              <input
                data-smarteditor="CheckBox"
                type="checkbox"
                className={configSmartR.classes.checkbox}
                checked={selecteds.includes(String(option[optionsId]))}
                value={option[optionsId]}
              ></input>
            ) : null}

            <span>
              {startIndex >= 0 ? (
                <>
                  {optionText.substring(0, startIndex)}
                  <strong>{optionText.substring(startIndex, endIndex)}</strong>
                  {optionText.substring(endIndex)}
                </>
              ) : (
                optionText
              )}
            </span>
          </div>
        );
      });
  }
  const classes = mapToCssModules(
    classNames(className, configSmartR.classes.select.container)
  );

  return (
    <div
      ref={containerRef}
      tabIndex={0}
      className={classes}
      onClick={toggleDropdown}
    >
      <EditorInput
        type={Input.Hidden}
        inputType="input"
        editorAttributes={{ ...editorAttributes, type: "hidden" }}
        inputRef={inputRef}
      ></EditorInput>
      <div
        className={`${configSmartR.classes.select.innerContainer} ${
          filterState.isOpen ? "open" : ""
        }`}
      >
        <div className={` ${filterState.isOpen ? "open" : ""}`}>
          {editorDescription}
        </div>
        <div
          className={configSmartR.classes.select.optionsContainer}
          style={{ width: `${containerWidth}px` }}
        >
          {optionsMultiple ? (
            <a
              href="#!"
              onClick={() => selectAllClick(selecteds.length < options.length)}
              className={configSmartR.classes.select.selectAll}
            >
              {selectLabelText}
            </a>
          ) : null}
          {optionsFilter ? (
            <input
              type="text"
              className={configSmartR.classes.select.searchInput}
              value={searchText}
              ref={searchInputRef}
              onChange={handleSearchChange}
              placeholder={searchPlaceholder}
            />
          ) : null}

          <div className={configSmartR.classes.select.dropdown}>
            {innerChildren}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Select;
