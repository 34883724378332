import { SideType } from "./const";

interface TimelineSeparatorProps {
  text: string;
  side?: SideType;
}
export const TimelineSeparator = ({ text, side }: TimelineSeparatorProps) => {
  return (
    <div className={`timeline-item separator ${side || ""}`}>
      <div className="timeline-block">
        <div className="time-show-btn mt-0">
          <div className="badge badge-primary-subtle w-lg font-size-16">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};
