import React, { useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import gomelius from "src/api/gomelius";
import { PermissaoMapa } from "src/PermissaoMapa";

export const withPermissionCheck = (
  WrappedComponent: React.ComponentType<any>,
  requiredPermission: number
) => {
  // Utiliza forwardRef para passar refs corretamente
  return forwardRef((props: any, ref: any) => {
    const navigate = useNavigate();

    useEffect(() => {
      // Verifica as permissões do usuário
      const usuarioAdministrador = gomelius.usuarioAdministrador();
      const permissoesUsuario = gomelius.obterPermissoes();
      // Verifica se o usuário tem permissão
      if (
        !usuarioAdministrador &&
        !permissoesUsuario.includes(
          PermissaoMapa.obterPosicaoBit(requiredPermission)
        )
      ) {
        navigate("/403", { replace: true });
      }
    }, [navigate]);

    return <WrappedComponent ref={ref} {...props} />;
  });
};
