import { AdminUtil, Util } from "src/util";
import { SideType } from "./const";
import { DateHelper } from "src/helpers";

interface TimelineItemProps {
  date: Date | string | object;
  icon?: string;
  title: string;
  footer?: string;
  side?: SideType;
  className?: string;
  onClick?: any;
  children?: any;
}

export const TimelineItem = ({
  date,
  icon,
  title,
  footer,
  side = SideType.LEFT,
  className,
  onClick,
  children,
}: TimelineItemProps) => {
  const timeZone = AdminUtil.obterFusoHorario();
  return (
    <div
      className={`timeline-item ${side === SideType.LEFT ? "left" : "right"} ${
        className || ""
      }`}
      onClick={onClick}
    >
      <div className="timeline-block">
        {/* Condicional para exibir o losango com o ícone ou a bolinha padrão */}
        {icon ? (
          <div className="timeline-icon">
            <div className="diamond">
              <i className={icon}></i>
            </div>
          </div>
        ) : (
          <div className="timeline-box-before"></div> // Mantém a bolinha original
        )}
        <div className="timeline-box">
          <div className="timeline-item-body">
            <div className="timeline-date text-muted">
              {DateHelper.getFormattedDateTimeHuman(date, timeZone)}
            </div>
            <h5 className="mt-3 font-size-16">{title}</h5>
            <div className="text-muted">
              <div className="mb-0">{children}</div>
            </div>
          </div>
          <div className="timeline-item-footer text-muted">{footer}</div>
        </div>
      </div>
    </div>
  );
};
