import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Column, ColumnSize } from "../../SmartR";
import { Icon } from "@ailibs/feather-react-ts";
import gomelius from "src/api/gomelius";
import { AdminUtil } from "src/util/AdminUtil";

interface BreadcrumbProps {
  funcao?: number;
  item: string;
  titulo: string;
  modulo: string;
  temFavorito?: boolean;
  podeRedimensionar?: boolean;
  maximizado?: boolean;
  onRedimensionarHandler?: any;
}

const Breadcrumb = ({
  funcao,
  modulo,
  item,
  titulo,
  temFavorito = false,
  podeRedimensionar = false,
  maximizado = false,
  onRedimensionarHandler,
}: BreadcrumbProps) => {
  const [favorito, setFavorito] = useState(
    temFavorito && funcao && AdminUtil.funcaoFavorita(funcao)
  );
  const handleFavorito = () => {
    if (!favorito) {
      gomelius
        .post(null, `Usuario/AdicionarFavorito/${funcao}`)
        .then(() => {
          AdminUtil.adicionarFavorito(funcao);
        })
        .catch(() => {});
    } else {
      gomelius
        .delete(funcao, "Usuario/RemoverFavorito")
        .then(() => {
          AdminUtil.removerFavorito(funcao);
        })
        .catch(() => {});
    }
    setFavorito(!favorito);
  };
  return (
    <Row>
      <Column xs={ColumnSize.Col12}>
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0">{titulo}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to="#">{modulo}</Link>
              </li>
              <li className="breadcrumb-item active">{item}</li>
              {temFavorito && (
                <li className={`breadcrum-ico ${favorito ? "favorite" : ""}`}>
                  <Icon
                    name="star"
                    className="icon-sm"
                    onClick={handleFavorito}
                    color={favorito ? "#f7cc53" : "currentColor"}
                  />
                </li>
              )}
              {podeRedimensionar && (
                <li className={`breadcrum-ico `}>
                  <Icon
                    name={!maximizado ? "maximize-2" : "minimize-2"}
                    className="icon-sm"
                    onClick={onRedimensionarHandler}
                  />
                </li>
              )}
            </ol>
          </div>
        </div>
      </Column>
    </Row>
  );
};

export default Breadcrumb;
