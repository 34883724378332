// action
import {
  erroAutenticarUsuario,
  selecionarCliente,
  selecionarEmpresa,
  usuarioAutenticadoSucesso,
} from "./reducer";

import gomelius from "src/api/gomelius";
import { inicializar } from "./reducer";

export const autenticarUsuario: any =
  (user: any, navigate: any) => async (dispatch: any) => {
    try {
      await gomelius
        .autenticarUsuario(user.email, user.senha)
        .then((dados: any) => {
          if (!dados.selecionarEmpresa && !dados.selecionarCliente) {
            dispatch(
              usuarioAutenticadoSucesso({
                usuario: dados.usuario,
                versao: dados.versao,
              })
            );
          } else {
            dispatch(erroAutenticarUsuario(""));
            if (dados.selecionarEmpresa) {
              dispatch(selecionarEmpresa(dados));
            } else {
              dispatch(selecionarCliente(dados));
            }
          }
        })
        .catch((error) => {
          dispatch(erroAutenticarUsuario("Verifique seu e-mail e senha"));
        });
    } catch (error) {
      dispatch(erroAutenticarUsuario("Verifique seu e-mail e senha"));
    }
  };

export const autenticarUsuarioCliente: any =
  (dados: any, navigate: any) => async (dispatch: any) => {
    try {
      await gomelius
        .autenticarUsuarioCliente(
          dados.usuarioId,
          dados.chavePreAutorizacao,
          dados.cliente
        )
        .then((dados: any) => {
          dispatch(
            usuarioAutenticadoSucesso({
              usuario: dados.usuario,
              versao: dados.versao,
            })
          );
        })
        .catch((error) => {
          dispatch(
            erroAutenticarUsuario(
              "Não foi possível verificar seu acesso neste empresa"
            )
          );
        });
    } catch (error) {
      dispatch(
        erroAutenticarUsuario(
          "Não foi possível verificar seu acesso neste empresa"
        )
      );
    }
  };

export const autenticarUsuarioClienteSuporte: any =
  (dados: any, navigate: any) => async (dispatch: any) => {
    try {
      await gomelius
        .autenticarUsuarioCliente(
          dados.usuarioId,
          dados.chavePreAutorizacao,
          dados.cliente,
          dados.suporteId
        )
        .then((dados: any) => {
          dispatch(
            usuarioAutenticadoSucesso({
              usuario: dados.usuario,
              versao: dados.versao,
            })
          );
        })
        .catch((error) => {
          dispatch(
            erroAutenticarUsuario(
              "Não foi possível continuar com a autenticação do usuário"
            )
          );
        });
    } catch (error) {
      dispatch(
        erroAutenticarUsuario(
          "Não foi possível continuar com a autenticação do usuário"
        )
      );
    }
  };
export const socialLogin: any =
  (data: any, navigate: any, type: any) => async (dispatch: any) => {
    try {
      let response;

      const socialdata = await response;

      if (socialdata) {
        localStorage.setItem("authUser", JSON.stringify(socialdata));
        dispatch(usuarioAutenticadoSucesso(socialdata));
        navigate("/Dashboard");
      }
    } catch (error) {
      dispatch(erroAutenticarUsuario(error));
    }
  };

export const logoutUser: any = (navigate: any) => async (dispatch: any) => {
  try {
    dispatch(
      inicializar({
        autenticado: false,
        usuario: null,
        versao: 0,
        renovarSessao: false,
      })
    );
    await gomelius.logout();
  } catch (error) {
    dispatch(erroAutenticarUsuario(error));
  }
};
